import React from "react";
import thronesLogo from "./logos/thrones_sm.png";
import darbaarLogo from "./logos/darbaar_sm.png";
import mehfilLogo from "./logos/mehfil_sm.png";
import moment from "moment";
import { getTableName } from "../../components/_utils/_utils";

export class PrintDesign extends React.PureComponent {
  render() {
    let { singleOrderData } = this.props;
    let { mergedProducts } = singleOrderData;
    let netAmount = Math.round(singleOrderData.netAmount);
    return (
      <>
        {singleOrderData && singleOrderData._id ? (
          <div className="" id="printableDiv">
            <div className="subcontainer">
              <h6
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "800",
                }}
              >
                RGS Food & Beverages
              </h6>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "800",
                }}
              >
                GST Number : 06ABHFR4615E1ZD
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "11px",
                  fontWeight: "800",
                }}
              >
                Address : Sector 28A, ALPHA INTERNATIONAL CITY, KARNAL , 132001
              </p>

              <div className="print_row mt-2">
                <div className="col-sm-4">
                  <img src={darbaarLogo} className={"mt-2 "} />
                </div>
                <div className="col-sm-4 align_center">
                  <img src={thronesLogo} />
                </div>
                <div className="col-sm-4 align_right">
                  <img src={mehfilLogo} className={"mt-2"} />
                </div>
              </div>

              <div className="divider"></div>
              <h6
                className="mt-1"
                style={{ fontSize: "13px", fontWeight: "800" }}
              >
                Name : {singleOrderData.customerName}
              </h6><h6
                className="mt-1"
                style={{ fontSize: "13px", fontWeight: "800" }}
              >
                Number : {singleOrderData.customerMobile}
              </h6>
              <h6
                className="mt-1"
                style={{ fontSize: "13px", fontWeight: "800" }}
              >
                GST : {singleOrderData.customerGst}
              </h6>
              <div className="divider border"></div>
              <div className="detail mt-1 ">
                <div className="print_row setting">
                  <div className="col-sm-6">
                    <p style={{ fontSize: "13px" }}>
                      <label style={{ fontWeight: "800" }}>Date: </label>{" "}
                      {moment(singleOrderData.date).format("DD/MM/YYYY")}
                    </p>
                    <p style={{ fontSize: "13px", fontWeight: "800" }}>
                      {moment(singleOrderData.time).format("hh:mm")}
                    </p>
                    <p style={{ fontSize: "13px" }}>
                      <label style={{ fontWeight: "800" }}>Cashier: </label>{" "}
                      Biller
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      <label style={{ fontWeight: "800" }}>Dine In: </label>{" "}
                      {getTableName(singleOrderData.tableNo)}
                    </p>
                    <p>{""}</p>
                    <p style={{ fontSize: "13px" }}>
                      <label style={{ fontWeight: "800" }}>Bill No. </label>{" "}
                      {singleOrderData.orderNo}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="table-design"
                style={{ borderTop: "1px solid black" }}
              >
                <div className="cols">
                  <table class="table print_table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="setWidth"
                          style={{
                            width: "70% !important",
                            fontWeight: "bold",
                          }}
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                            width: "5%",
                            fontWeight: "800",
                          }}
                        >
                          Qty
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                            width: "5%",
                            fontWeight: "800",
                          }}
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                            width: "5%",
                            fontWeight: "800",
                          }}
                        >
                          Amt
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mergedProducts && mergedProducts.length
                        ? mergedProducts.map((item) => {
                            return (
                              <>
                                <tr className="sett" key={item._id}>
                                  <th
                                    scope="row"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item.name}
                                  </th>

                                  <th
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item.quantity}&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Rs. {item.price}
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Rs. {item.totalAmount}
                                  </th>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="divider"></div>
              </div>
              <div className="table-design">
                <div className="cols">
                  <table class="table print_table">
                    <tbody>
                      <tr className="sett">
                        <th
                          scope="row"
                          style={{ fontSize: "13px", fontWeight: "800" }}
                        >
                          Total Qty:
                        </th>

                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {singleOrderData.totalQty}&nbsp;&nbsp;&nbsp;
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "800",
                            color: "black",
                          }}
                        >
                          Sub Total:
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Rs. {singleOrderData.totalAmount}
                        </th>
                      </tr>
                      <tr className="sett">
                        <th
                            scope="row"
                            style={{fontSize: "13px", fontWeight: "800"}}>
                          Dis. {singleOrderData.discountPer ? `(${singleOrderData.discountPer}%)` : ""}
                        </th>
                        <td
                            style={{textAlign: "right", fontSize: "13px"}}
                        ></td>
                        <td
                          style={{ textAlign: "right", fontSize: "13px" }}
                        ></td>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Rs. {singleOrderData.discount}
                        </th>
                      </tr>

                      <tr className="sett">
                        <th
                          scope="row"
                          style={{ fontSize: "13px", fontWeight: "800" }}
                        >
                          Service tax
                        </th>

                        <td
                          style={{ textAlign: "right", fontSize: "13px" }}
                        ></td>
                        <td
                          style={{ textAlign: "right", fontSize: "13px" }}
                        ></td>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Rs. {singleOrderData.serviceTaxAmount}
                        </th>
                      </tr>

                      <tr className="sett">
                        <th
                          scope="row"
                          style={{ fontSize: "13px", fontWeight: "800" }}
                        >
                          CGST
                        </th>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        ></td>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          2.5%
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Rs. {singleOrderData.cgstAmount}
                        </th>
                      </tr>
                      <tr className="sett">
                        <th
                          scope="row"
                          style={{ fontSize: "13px", fontWeight: "800" }}
                        >
                          SGST
                        </th>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "800",
                          }}
                        ></td>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          2.5%
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Rs. {singleOrderData.sgstAmount}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="divider"></div>

              <div className="table-design">
                <div className="cols">
                  <table class="table print_table">
                    <tbody>
                      <tr className="sett">
                        <th scope="row" style={{ fontSize: "13px" }}></th>

                        <td
                          style={{ textAlign: "right", fontSize: "13px" }}
                        ></td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "800",
                            color: "black",
                          }}
                        >
                          <p>Round Off</p>
                        </td>
                        <th
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          <p className="total">
                            +
                            {(netAmount - singleOrderData.netAmount).toFixed(2)}
                          </p>
                        </th>
                      </tr>

                      <tr className="sett">
                        <th scope="row" style={{ fontSize: "13px" }}></th>

                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "800",
                          }}
                        ></td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "13px",
                            fontWeight: "800",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "right",
                              fontWeight: "800",
                              fontSize: "13px",
                              color: "black",
                            }}
                          >
                            {" "}
                            Grand Total
                          </p>
                        </td>
                        <th style={{ textAlign: "right", fontSize: "13px" }}>
                          <p
                            className="total"
                            style={{
                              textAlign: "right",
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            <b> Rs. {netAmount} </b>
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="divider"></div>
              </div>

              <p
                  style={{
                    textAlign: "center",
                    marginTop: "12px",
                    fontWeight: "800",
                    fontSize: "13px",
                  }}
              >
                Thanks
              </p>
            </div>
            <div style={{pageBreakBefore: "always"}}></div>
          </div>
        ) : null}
      </>
    );
  }
}

export default PrintDesign;
