import moment from "moment";

export {default as Avatar} from "./Avatar";
export {default as Button} from "./Button";
export {default as TableButton} from "./Button/TableButton";
export {default as Card} from "./Card";
export {default as Checkbox} from "./CheckBox";
export {default as DatePicker} from "./DatePicker";
export {default as Drawer} from "./Drawer";
export {default as DropDown} from "./DropDown";
export {default as Form} from "./Form";
export {default as HyperLink} from "./HyperLink";
export {default as Icon} from "./Icon";
export {default as Modal} from "./Modal";
export {default as notification} from "./Notification";
export {default as Pagination} from "./Pagination";
export {default as Popconfirm} from "./Popconfirm";
export {SelectRc as Select, SelectComponent2 as Select2} from "./Select";
export {default as Table} from "./Table";
export {default as Tabs} from "./Tabs";
export {default as TimePicker} from "./TimePicker";
export {default as Tooltip} from "./Tooltip";
export {titleComponent as Title} from "./component";
export {default as InputBox} from "./inputComponent";

export const displayDate = (date) => {
    if (date) {
        // return moment(date).format("DD MMMM YYYY");
        return moment(date).format("DD-MM-YYYY");
    } else {
        return null;
    }
};
export const appDisplayDate = (date) => {
    if (date) {
        return moment(date).format("DD/MM/YYYY");
    } else {
        return null;
    }
};
export const displayTime = (date) => {
    if (date) {
        return moment(date).format("hh:mm a");
    } else {
        return null;
    }
};
export const longDisplayDate = (date) => {
    if (date) {
        return moment(date).format("DD-MM-YYYY | h:mm A");
    } else {
        return null;
    }
};

export const DefaultTablePagination = (
    newParams,
    defaultPageSize = 20,
    pageSizeOptions = ["20", "50", "75", "100"]
) => {
    let params = {
        defaultPageSize,
        pageSizeOptions,
        ...newParams,
        position: "top",
    };
    return params;
};


export const getStatusColor = (status) => {
    let colorObj = [
        "#337ab7",
        "#5cb85c",
        "#5bc0de",
        "#f0ad4e",
        "#d9534f",
        "#0000FF",
        "#FF1493",
        "#F4A460",
        "#708090",
        "#808080",
        "#5F9EA0",
        "#1890ff",
        "#0872BC",
    ];
    return (
        <span className={"statusLabel"} style={{borderColor: "#4B49AC"}}>
      {status}
    </span>
    );
};
export const fixed2Digit = (value) => {
    if (value) {
        return parseFloat(parseFloat(value).toFixed(2));
    } else {
        return 0;
    }
};
export const getItemLocalstorage = (key) => {
    const user = localStorage.getItem(key);
    const companydata = JSON.parse(user);
    return companydata;
}
export const calculation = (state, stateOfSupply = '', companyState) => {
    console.log(stateOfSupply, companyState, "companyState")
    return new Promise((resolve) => {
        let statesAreEqual = stateOfSupply == companyState ? true : false
        let quantity = parseFloat(
            state.quantity ? parseFloat(state.quantity).toFixed(3) : 0
        );
        let salePrice = parseFloat(
            state.price ? parseFloat(state.price).toFixed(2) : 0
        );
        let discount = parseFloat(
            state.discount ? parseFloat(state.discount).toFixed(2) : 0
        );
        let gst = parseFloat(state.gst ? parseInt(state.gst) : 0);
        let subTotal = 0;
        let discountAmount = 0;
        let afterDisc = 0;
        let gstAmount = 0;
        let netAmount = 0;
        let taxableAmount = 0;
        let totalAmount = 0;
        let cgstTax = 0;
        let sgstTax = 0;
        let igstTax = 0;

        totalAmount = parseFloat(salePrice * quantity);


        discountAmount = discount
            ? parseFloat(parseFloat((discount / 100) * totalAmount).toFixed(2))
            : 0;

        afterDisc =
            parseFloat(totalAmount) -
            (discountAmount ? parseFloat(discountAmount) : 0);

        if (state.reportType == "Inclusive") {
            subTotal = parseFloat(((afterDisc * 100) / (100 + gst)).toFixed(2));

            // taxableAmount = parseFloat(subTotal.toFixed(2));

            if (gst !== "Exempted") {
                gstAmount = parseFloat((state.gst * afterDisc) / 100).toFixed(2);
            }
            taxableAmount = fixed2Digit(
                parseFloat(afterDisc) - parseFloat(gstAmount)
            );

            netAmount = fixed2Digit(
                parseFloat(taxableAmount) + parseFloat(gstAmount)
            );

        } else {
            taxableAmount = parseFloat(afterDisc).toFixed(2);

            if (gst !== "Exempted") {
                gstAmount = parseFloat((state.gst * taxableAmount) / 100).toFixed(2);
            }

            netAmount = Math.round(
                parseFloat(taxableAmount) + parseFloat(gstAmount)
            ).toFixed(2);
        }


        let objToSet = {
            ...state,
            totalAmount,
            netAmount: netAmount ? fixed2Digit(netAmount) : 0,
            taxableAmount: taxableAmount ? fixed2Digit(taxableAmount) : 0,
            gst: state.gst ? fixed2Digit(state.gst) : 0,
            gstAmount: gstAmount ? fixed2Digit(gstAmount) : 0,
            discount: state.discount ? fixed2Digit(state.discount) : 0,
            cgstAmount: 0,
            sgstAmount: 0,
            igstAmount: 0,
            cgstTax: cgstTax ? fixed2Digit(cgstTax) : 0,
            sgstTax: sgstTax ? fixed2Digit(sgstTax) : 0,
            igstTax: igstTax ? fixed2Digit(igstTax) : 0,
            salePrice: state.salePrice,
        };

        if (statesAreEqual) {
            let amount1 = gstAmount / 2;
            amount1 = fixed2Digit(amount1);

            objToSet.cgstAmount = amount1;
            objToSet.sgstAmount = amount1;
            let taxValue = state.gst / 2;
            objToSet.sgstTax = fixed2Digit(taxValue);
            objToSet.cgstTax = fixed2Digit(taxValue);
        } else {
            objToSet.igstAmount = fixed2Digit(gstAmount);
            objToSet.igstTax = fixed2Digit(state.gst);
        }

        objToSet.discountAmount = discountAmount ? fixed2Digit(discountAmount) : 0;
        resolve(objToSet);
    });
};
export let kitchenTypes = ['kitchen', 'tandoor', 'beverages']

export let adminTypes = ['admin', 'cashier', 'account']
