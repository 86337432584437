import React, {useEffect, useRef, useState} from "react";
import PageHeader from "../../../components/Elements/pageHeader";
import {
    OrderPaymentFxn,
    TransferTableFxn,
    addOrderFxn,
    editKotFxn,
    getCouponDisFxn,
    getOldKotFxn,
    getPrintProductsFxn,
    groupedProductFxn,
    saveOrderFxn,
    tableListFxn,
} from "../actions";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import _, {size, toArray} from "lodash";
import Modal from "../../../components/Elements/Modal";
import Image from "../iconss.png";
import {
    Card,
    InputBox,
    notification,
    Form,
} from "../../../components/Elements/appUtils";
import {FetchSpecialNotes} from "../actions";
import {
    RenderImage,
    getItemLocalstorage,
    getVegStatus,
} from "../../../components/_utils/_utils";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import AddCustomerDrawerPos from "../../../components/addCustomerDrawerPos";
import {category} from "../../../components/_utils/appUtils";
import {updateKotStatusFxn, updatePriorityFxn} from "../../kot/actions";
import {editableInputTypes} from "@testing-library/user-event/dist/utils";
import {useReactToPrint} from "react-to-print";
import PrintDesign from "../../print/printDesign";
import {toBeValid} from "@testing-library/jest-dom/matchers";

let initState = {
    totalAmount: 0,
    tax: 0,
    netAmount: 0,
};
const PosComponent = (props) => {
    const userType = getItemLocalstorage("user")["userType"];
    // console.log(userType, "usertype");
    let dispatch = useDispatch();
    const componentRef = useRef();
    let navigate = useNavigate();
    const options = {
        autoplay: false,
        loop: false,
        nav: true,
        dots: false,
        dotClass: "owl-dot",
        // margin: 15,
        autoplayTimeout: 2000,
        responsive: {
            0: {
                items: 10,
                nav: true,
            },
            400: {
                items: 4,
                nav: false,
            },
            600: {
                items: 6,
                nav: false,
            },
            800: {
                items: 6,
                nav: true,
                loop: false,
            },
            1200: {
                items: 8,
                nav: true,
                loop: false,
            },
        },
    };
    let [productList, setProductList] = useState([]);
    let [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    let [activeIndex, setActiveIndex] = useState(0);
    let [selectedProducts, setSelectedProducts] = useState([]);
    let [OldKots, setOldKots] = useState([]);
    let [noOfKots, setNoOfKots] = useState(0);
    let [menuName, setMenuName] = useState("");
    let [allData, setAllData] = useState([]);
    let [OldKotTotalAmount, setOldKotTotalAmount] = useState(0);
    let [totalProductsLength, setTotalProductsLength] = useState("");
    let [orderId, setOrderId] = useState("");
    let [formData, setFormData] = useState({});
    let [editdata, seteditData] = useState({});
    let [tableNo, setTableNo] = useState([]);
    let [state, setState] = useState(initState);
    let [isModalOpen, setIsModalOpen] = useState(false);
    let [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    let [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    let [isOldkotModalOpen, setIsOldkotModalOpen] = useState(false);
    let [isSpecialNoteModalOpen, setIsSpecialNoteModalOpen] = useState(false);
    let [isTransferTableModalOpen, setisTransferTableModalOpen] = useState(false);
    let [editKotModal, setEditKotModal] = useState(false);
    let [kotId, setKotId] = useState("");
    let [editKotProducts, setEditKotProducts] = useState([]);
    let [refreshKey, setRefreshKey] = useState(moment());
    let [variantData, setVariantData] = useState({});
    let [bgIndex, setBgIndex] = useState(null);
    let [currentStatus, setCurrentStatus] = useState("");
    let [productIds, setProductIds] = useState("");
    let [varient, setvarient] = useState("");
    let [specialNoteList, setspecialNoteList] = useState([]);
    let [customerState, setCustomerState] = useState({
        visible: false,
        lead: {},
    });
    let [singleOrderData, setSingleOrderData] = useState({});
    const [discountType, setDiscountType] = useState("discount");
    const handleDiscountChange = (value) => {
        setDiscountType(value);
    };
    const [discountper, setdiscountper] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [disCouponCode, setDisCouponCode] = useState("");
    const [discountCoupon, setDiscountCoupon] = useState("");
    const [couponId, setCouponId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerMobile, setCustomerMobile] = useState("");
    const [notes, setNotes] = useState("");
    const [tableName, setTableName] = useState("");
    const [tableNumber, setTableNumber] = useState("");
    const [serviceTaxIncluded, setServiceTaxIncluded] = useState(true);
    const [guest, setGuest] = useState(false);
    const [tableKey, setTableKey] = useState(null);
    let [clickedProId, seClickedProId] = useState("");
    const handleCheckboxChange = () => {
        setServiceTaxIncluded(!serviceTaxIncluded);
    };

    const [paymentMethod, setPaymentMethod] = useState("");
    const handlePaymentMethodChange = (value) => {
        setPaymentMethod(value);
    };

    // console.log(varient, "varient");

    let [paymentData, setPaymentData] = useState({
        PaymentType: "",
        customerPaid: 0,
        returnToCustomer: 0,
        tip: 0,
        setteledAmount: 0,
        totalAmount: 0,
        orderNo: "",
        orderId: "",
        cashDiscount: 0,
        finalAmount: 0,
    });

    useEffect(() => {
        if (paymentData.cashDiscount) {
            let finalAmount = paymentData.totalAmount - paymentData.cashDiscount;
            setPaymentData({...paymentData, finalAmount: finalAmount});
        } else {
            setPaymentData({...paymentData, finalAmount: paymentData.totalAmount});
        }
    }, [paymentData.cashDiscount]);

    let calculatePrice = () => {
        let customerPaid = paymentData.customerPaid || 0;
        let returnToCustomer = 0;
        let tip = paymentData.tip || 0;
        let settledAmount = 0;

        if (tip) {
            tip = parseFloat(tip);
        }

        if (customerPaid) {
            settledAmount = parseFloat(paymentData.finalAmount + tip).toFixed(2);
        }
        returnToCustomer = parseFloat(customerPaid) - settledAmount;

        setPaymentData({
            ...paymentData,
            setteledAmount: settledAmount,
            returnToCustomer: returnToCustomer > 0 ? returnToCustomer.toFixed(2) : 0,
        });
    };

    useEffect(() => {
        calculatePrice();
    }, [paymentData.customerPaid, paymentData.tip]);

    let handlePayment = async () => {
        paymentData.paymentType = paymentMethod;
        if (!paymentData.paymentType) {
            notification.warning({message: "Choose payment method."});
            return;
        }
        if (!paymentData.customerPaid) {
            notification.warning({message: "Enter Paid amount."});
            return;
        }
        delete paymentData.totalAmount;
        if (couponId && couponCode) {
            paymentData.couponId = couponId;
            paymentData.couponCode = couponCode;
        }

        let x = await dispatch(OrderPaymentFxn(paymentData));
        if (x && x.success) {
            setPaymentData({});
            setIsPaymentModalOpen(false);
            setTimeout(() => {
                navigate("/tables");
            }, 1500);
        }
    };

    useEffect(() => {
        if (kotId) {
            const kot = OldKots.find((kot) => kot._id === kotId);
            if (kot) {
                setEditKotProducts(kot.products);
            }
        }
    }, [kotId]);

    useEffect(() => {
        events.calculatePrice();
    }, [selectedProducts, OldKotTotalAmount, serviceTaxIncluded, discountper]);
    let events = {
        showCustomerModal: (lead) => {
            setCustomerState({
                visible: true,
                lead: lead,
            });
        },
        hide: () => {
            setCustomerState({
                visible: false,
            });
            // events.reloadTable();
        },
        addProduct: (data) => {
            data.productId = data._id;

            delete data._id;
            let clonePro = _.clone(selectedProducts);
            let findPro = _.find(clonePro, (item) => {
                if (item.productId == data.productId && item.price == data.price) {
                    return item.productId == data.productId;
                } else if (
                    item.productId == data.productId &&
                    item.price != data.price
                ) {
                    return null;
                }
            });
            if (findPro) {
                findPro.quantity = findPro.quantity + 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            } else {
                clonePro.push({...data, quantity: 1, totalAmount: data.price});
            }
            // console.log(clonePro, "clonePro");
            setTimeout(() => {
                seClickedProId("");
            }, 200);
            setSelectedProducts(clonePro);
        },
        incQty: (data) => {
            let clonePro = _.clone(selectedProducts);
            let findPro = _.find(clonePro, (item) => {
                return item.productId == data.productId && item.price == data.price;
            });
            if (findPro) {
                findPro.quantity = findPro.quantity + 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            setSelectedProducts(clonePro);
        },
        descQty: (data) => {
            let clonePro = _.clone(selectedProducts);
            let findPro = _.find(clonePro, (item) => {
                return item.productId == data.productId && item.price == data.price;
            });
            if (findPro) {
                findPro.quantity = findPro.quantity - 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            if (findPro.quantity == 0) {
                selectedProducts = _.reject(clonePro, (item) => {
                    return item.productId == data.productId && item.price == data.price;
                });
                setSelectedProducts(selectedProducts);
            } else {
                setSelectedProducts(clonePro);
            }
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        calculatePrice: () => {
            let clonePro = _.clone(selectedProducts);
            let discount;
            if (discountper) {
                discount = discountper;
            } else {
                discount = 0;
            }
            let gst = 5;
            let servicetax;

            if (serviceTaxIncluded === true) {
                servicetax = 5;
            } else {
                servicetax = 0;
            }
            let subTotal = 0;
            let cgst = gst / 2;
            let sgst = gst / 2;
            let totalAmount = 0;
            let discountAmount = 0;
            let serviceTaxAmount = 0;
            let cgstAmount = 0;
            let sgstAmount = 0;
            let tax = 0;
            let netAmount = 0;
            _.each(clonePro, (item) => {
                let amount =
                    parseFloat(item.price) * (item.quantity ? item.quantity : 1);
                totalAmount = parseFloat(totalAmount) + amount;
                subTotal = subTotal + amount;

                if (discount) {
                    discountAmount = (totalAmount * discount) / 100;
                }
                const discountedAmount = parseFloat(totalAmount - discountAmount);
                serviceTaxAmount = (discountedAmount * servicetax) / 100;
                cgstAmount = (discountedAmount * cgst) / 100;
                sgstAmount = (discountedAmount * sgst) / 100;
                tax = serviceTaxAmount + cgstAmount + sgstAmount;
                netAmount = discountedAmount + tax;
            });
            // console.log(OldKotTotalAmount,'--------------demo')
            if (OldKotTotalAmount) {
                //if old kot then adding the previous amount to the total amount
                totalAmount = totalAmount + OldKotTotalAmount;
                subTotal = subTotal + OldKotTotalAmount;
                if (discount) {
                    discountAmount = (totalAmount * discount) / 100;
                }
                const discountedAmount = parseFloat(totalAmount - discountAmount);
                serviceTaxAmount = (discountedAmount * servicetax) / 100;
                cgstAmount = (discountedAmount * cgst) / 100;
                sgstAmount = (discountedAmount * sgst) / 100;
                tax = serviceTaxAmount + cgstAmount + sgstAmount;
                netAmount = discountedAmount + tax;
            }
            // console.log(netAmount, 'netAmount------------')
            events._updateState({
                subTotal: subTotal.toFixed(2),
                totalAmount,
                discountAmount: discountAmount.toFixed(2),
                cgstAmount: cgstAmount.toFixed(2),
                sgstAmount: sgstAmount.toFixed(2),
                serviceTaxAmount: serviceTaxAmount.toFixed(2),
                tax: tax.toFixed(2),
                netAmount: netAmount.toFixed(2),
            });
        },
        updatePriority: async (data, productId = "") => {
            let {
                message,
                success,
                data: kotData,
            } = await updatePriorityFxn({
                productId,
                kotId: data._id,
            });
            if (success && productId) {
                notification.warning({message: message});
            }
        },
        refreshKot: (data) => {
            let cloneData = _.clone(OldKots);
            let findKot = _.find(cloneData, (item) => {
                return item._id === data._id;
            });

            if (findKot) {
                findKot.products = data.products;
                setOldKots(cloneData);
            }
        },
        incEditQty: (data) => {
            let clonePro = _.clone(editKotProducts);
            let findPro = _.find(clonePro, (item) => {
                return (
                    item.productId._id == data.productId._id && item.price == data.price
                );
            });
            if (findPro) {
                findPro.quantity = findPro.quantity + 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            setEditKotProducts(clonePro);
        },

        desEditcQty: (data) => {
            let clonePro = _.clone(editKotProducts);
            let findPro = _.find(clonePro, (item) => {
                return (
                    item.productId._id == data.productId._id && item.price == data.price
                );
            });
            if (findPro) {
                findPro.quantity = findPro.quantity - 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            if (findPro.quantity == 0) {
                editKotProducts = _.reject(clonePro, (item) => {
                    return (
                        item.productId._id == data.productId._id && item.price == data.price
                    );
                });
                setEditKotProducts(editKotProducts);
            } else {
                setEditKotProducts(clonePro);
            }
        },

        getCouponDis: async () => {
            if (customerName) {
                let resp = await dispatch(
                    getCouponDisFxn({
                        couponCode: disCouponCode,
                        mobileNo: customerMobile,
                    })
                );
                if (resp && resp.success) {
                    setdiscountper(resp.result.discount);
                    setCouponCode(resp.result.couponCode);
                    setDisCouponCode("");
                    setCouponId(resp.result._id);
                } else {
                    setdiscountper("");
                    setDisCouponCode("");
                }
            } else {
                notification.warning({message: "Please choose Customer"});
            }
        },
    };

    const apiRequest1 = (params) => {
        return new Promise(async (resolve) => {
            const data = await FetchSpecialNotes({...params});
            // console.log(data, "specialNoteList");
            setspecialNoteList(data.data);

            resolve(data);
        });
    };
    useEffect(() => {
        if (discountCoupon) {
            events.getCouponDis();
        }
    }, [discountCoupon]);

    useEffect(() => {
        apiRequest1();
    }, []);

    let loadAllData = async () => {
        let {data} = await groupedProductFxn();

        let activeProducts = data.map((item) => {
            let categoriesWithActiveProducts = item.category.map((category) => {
                let activeProductsInCategory = category.product?.filter(
                    (product) => product.productStatus === "Active"
                );
                return {...category, product: activeProductsInCategory};
            });
            return {...item, category: categoriesWithActiveProducts};
        });

        // console.log(activeProducts, "filteredData");
        setAllData(activeProducts);

        if (category && category.length) {
            setMenuName(category[0].key);
        }
    };

    let loadProduct = async () => {
        let cloneAllData = _.clone(allData);
        let findDoc = _.find(cloneAllData, (item) => {
            return item._id == menuName.toUpperCase();
        });
        if (findDoc) {
            findDoc.category.sort((a, b) => a.index - b.index);
            setProductList(findDoc.category);
            setRefreshKey(moment());
        }
    };
    useEffect(() => {
        if (menuName) {
            loadProduct();
        }
    }, [menuName]);

    useEffect(() => {
        loadAllData();
    }, []);

    // console.log(productList, "productlist");
    useEffect(() => {
        setTimeout(() => {
            const currentURL = window.location.href;
            const tableNumber = currentURL.split("/").pop();
            setTableNo(tableNumber);
        }, 1000);
    }, [tableKey]);

    const getOldKotData = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await getOldKotFxn({...params, tableNo: tableNo});
                if (data) {
                    setOldKots(data.data.data);
                    setNoOfKots(data.data.total);
                    setOldKotTotalAmount(data.totalAmount);
                    setCurrentStatus(data.currentStatus);
                    const totalProductsLength = data.data.data.reduce((acc, kot) => {
                        return acc + kot.products.length;
                    }, 0);
                    setOrderId(data.data.data[0].orderId);
                    setTotalProductsLength(totalProductsLength);
                    if (data.discountPer) {
                        setdiscountper(data.discountPer);
                    }
                    if (data.couponCode) {
                        setCouponCode(data.couponCode);
                    }
                    if (data.customerName) {
                        setCustomerMobile(data.customerMobile);
                        setCustomerName(data.customerName);
                    }
                    setServiceTaxIncluded(data.serviceTaxIncluded);
                }
            } catch (error) {
                // console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        if (tableNo) {
            getOldKotData();
        }
    }, [tableNo]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (singleOrderData && singleOrderData._id) {
            handlePrint();
        }
    }, [singleOrderData]);

    let loadOrderData = async (orderId) => {
        let params = {orderId};
        let resp = await dispatch(getPrintProductsFxn(params));
        setSingleOrderData(resp);
    };

    let handleAddOrder = async (selectedProducts, kotSubmitType) => {
        formData.products = selectedProducts;
        // formData.products.forEach((product) => {
        //   product.specialNote = selectedNote;
        // });

        formData.tableNo = tableNo;

        const {
            totalAmount,
            discountAmount,
            cgstAmount,
            sgstAmount,
            serviceTaxAmount,
            tax,
            netAmount,
            subTotal,
        } = state;
        formData.subTotal = subTotal;
        formData.totalAmount = totalAmount;
        formData.discount = discountAmount;
        formData.cgstAmount = cgstAmount;
        formData.sgstAmount = sgstAmount;
        formData.serviceTaxAmount = serviceTaxAmount;
        formData.serviceTaxIncluded = serviceTaxIncluded;
        formData.tax = tax;
        formData.netAmount = netAmount;
        formData.kotSubmitType = kotSubmitType;
        formData.guest = guest;
        formData.customerName = customerName;

        if (selectedProducts.length == 0) {
            notification.warning({message: "Please Add Some Food Item To Add Kot"});
            return;
        }
        let x = await dispatch(addOrderFxn(formData));
        if (x && x.success == true) {
            setSelectedProducts([]);
        }
    };

    let handleEditkot = async (editKotProducts, kotSubmitType) => {
        editdata.products = editKotProducts;
        editdata.tableNo = tableNo;
        editdata.kotId = kotId;
        editdata.kotSubmitType = kotSubmitType;
        editdata.serviceTaxIncluded = serviceTaxIncluded;
        editdata.discountPer = discountper;
        let x = await dispatch(editKotFxn(editdata));
        if (x && x.success == true) {
            setEditKotModal(false);
        }
    };

    let handleSaveOrder = async (selectedProducts, orderSubmitType) => {
        formData.products = selectedProducts;
        formData.tableNo = tableNo;
        const {
            totalAmount,
            discountAmount,
            cgstAmount,
            sgstAmount,
            serviceTaxAmount,
            tax,
            netAmount,
            subTotal,
        } = state;
        formData.subTotal = subTotal;
        formData.totalAmount = totalAmount;
        formData.discount = discountAmount;
        formData.discountPer = discountper;
        formData.cgstAmount = cgstAmount;
        formData.sgstAmount = sgstAmount;
        formData.serviceTaxAmount = serviceTaxAmount;
        formData.tax = tax;
        formData.netAmount = netAmount;
        formData.orderId = orderId;
        formData.orderSubmitType = orderSubmitType;
        formData.serviceTaxIncluded = serviceTaxIncluded;
        formData.customerName = customerName;
        formData.customerMobile = customerMobile;
        if (couponId && couponCode) {
            formData.couponId = couponId;
            formData.couponCode = couponCode;
        }
        if (discountType) {
            formData.discountType = discountType;
        }
        let x = await dispatch(saveOrderFxn(formData));
        if (x && x.success == true) {
            loadOrderData(orderId);
            setTimeout(() => {
                getOldKotData();
            }, 1500);
        }
    };
    let handleSave = async (selectedProducts, orderSubmitType) => {
        formData.products = selectedProducts;
        formData.tableNo = tableNo;
        const {
            totalAmount,
            discountAmount,
            cgstAmount,
            sgstAmount,
            serviceTaxAmount,
            tax,
            netAmount,
            subTotal,
        } = state;
        formData.subTotal = subTotal;
        formData.totalAmount = totalAmount;
        formData.discount = discountAmount;
        formData.discountPer = discountper;
        formData.cgstAmount = cgstAmount;
        formData.sgstAmount = sgstAmount;
        formData.serviceTaxAmount = serviceTaxAmount;
        formData.tax = tax;
        formData.netAmount = netAmount;
        formData.orderId = orderId;
        formData.orderSubmitType = orderSubmitType;
        formData.serviceTaxIncluded = serviceTaxIncluded;
        formData.customerName = customerName;
        formData.customerMobile = customerMobile;
        if (couponId && couponCode) {
            formData.couponId = couponId;
            formData.couponCode = couponCode;
        }
        if (discountType) {
            formData.discountType = discountType;
        }
        let x = await dispatch(saveOrderFxn(formData));
        if (x && x.success == true) {
            // loadOrderData(orderId);
            setTimeout(() => {
                getOldKotData();
            }, 1500);
        }
    };

    const resp = useSelector((state) => {
        let {isRefreshKot, kotData, isKotSaved, isOrderTransferred, newTableNo} = state.global;
        if (isRefreshKot) {
            events.refreshKot(kotData);
            getOldKotData();
            dispatch({type: "REFRESH_KOT", kotData: {}, isRefreshKot: false});
        }
        if (isKotSaved) {
            getOldKotData();
            dispatch({type: "KOT_SAVED", kotIds: [], isKotSaved: false});
        }
        if (isOrderTransferred) {
            if (tableNo !== newTableNo) {
                navigate(`/pos/${newTableNo}`);
            }
            dispatch({type: "TRANSFER_ORDER", orderId: "", isOrderTransferred: false, newTableNo: ""});
        }
    });

    const handleSearch = () => {
        if (searchTerm) {
            const filteredProducts = allData.flatMap((category) =>
                category.category.flatMap(
                    (subcategory) =>
                        subcategory.product // Check if subcategory.product is defined
                            ? subcategory.product.filter((product) => {
                                const regex = new RegExp(searchTerm, "ig");

                                return product.name.match(regex);
                                // return product.name.toLowerCase().includes(searchTerm.toLowerCase())
                            })
                            : [] // Return an empty array if subcategory.product is undefined
                )
            );

            setFilteredProducts(filteredProducts);
        } else {
            notification.warning({message: "Please Enter Name"});
        }
    };

    const handleAddNote = () => {
        if (notes !== "") {
            const updatedProducts = selectedProducts.map((product) => {
                if (product.productId === productIds && product.variant == varient) {
                    return {...product, specialNote: notes};
                }
                return product;
            });

            setSelectedProducts(updatedProducts);
            setIsSpecialNoteModalOpen(false);
            setNotes("");
        } else {
            // Show message if note is empty
            notification.warning({message: "Select Special Note"});
        }
    };

    const handleReset = () => {
        setFilteredProducts([]);
        setSearchTerm("");
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    let buttonComponent = (
        <div className="d-flex">
            {/* <button
                className="btn btn-success ms-1"
                onClick={() => {
                    handleAddOrder(selectedProducts, "kot");
                }}
            >
                Kot Save
            </button> */}
            <button
                className="btn btn-success ms-1"
                onClick={() => {
                    handleAddOrder(selectedProducts, "kotAndPrint");
                }}
            >
                kot & print
            </button>
            {userType != "captain" && (
                <button
                    className="btn btn-success ms-1"
                    onClick={() => {
                        // const pendingKOTs = OldKots.filter(
                        //     (kot) => kot.status === "Pending"
                        // );
                        // if (pendingKOTs.length > 0) {
                        //     notification.warning({message: "Some KOTs Are Still Pending"});
                        // } else {
                        //     handleSaveOrder(
                        //         selectedProducts
                        //         // , "saveAndPrint"
                        //     );
                        // }
                        handleSaveOrder(selectedProducts);
                    }}
                >
                    Bill Save & Print
                </button>
            )}
        </div>
    );
    let finalbutton = (
        <div className="d-flex">
            {userType != "captain" && (
                <>
                    <button
                        className="btn btn-success ms-1"
                        onClick={() => {
                            handleSave(selectedProducts);
                            // const pendingKOTs = OldKots.filter(
                            //     (kot) => kot.status === "Pending"
                            // );
                            // if (pendingKOTs.length > 0) {
                            //     notification.warning({
                            //         message: "Some KOTs Are Still Pending",
                            //     });
                            // } else {
                            //     handleSave(
                            //         selectedProducts
                            //     );
                            // }
                        }}
                    >
                        Save
                    </button>
                    <button
                        className="btn btn-success ms-1"
                        onClick={() => {
                            handleSaveOrder(selectedProducts);
                            // const pendingKOTs = OldKots.filter(
                            //     (kot) => kot.status === "Pending"
                            // );
                            // if (pendingKOTs.length > 0) {
                            //     notification.warning({
                            //         message: "Some KOTs Are Still Pending",
                            //     });
                            // } else {
                            //     handleSaveOrder(
                            //         selectedProducts
                            //         // , "saveAndPrint"
                            //     );
                            // }
                        }}
                    >
                        Bill Save & Print
                    </button>
                    <button
                        onClick={() => {
                            setIsPaymentModalOpen(true);
                            setPaymentData({
                                totalAmount: state.netAmount,
                                finalAmount: state.netAmount,
                                orderNo: OldKots.orderNo,
                                orderId: orderId,
                            });
                        }}
                        className="btn btn-success ms-1"
                    >
                        Pay
                    </button>
                </>
            )}
        </div>
    );

    const handleSaveButtonClick = () => {
        setIsDiscountModalOpen(false);
        if (discountper !== "" && discountper <= 15) {
            setdiscountper(discountper);
        } else if (discountper > 15) {
            setdiscountper(0);
            notification.warning({
                message: "Discount Cannot Be Greater Than 15%",
            });
        } else {
            setdiscountper("");
        }
    };

    const location = useLocation();
    const path = location.pathname;
    const segments = path.split("/");
    const lastSegment = segments[segments.length - 1];

    useEffect(() => {
        if (lastSegment.startsWith("g-")) {
            setGuest(true);
        } else {
            setGuest(false);
        }
    }, [lastSegment]);

    let [tableList, setTableList] = useState([]);
    let [tableNumbers, setTableNumbers] = useState([]);

    let loadTables = async () => {
        let {data} = await tableListFxn();
        data = data.filter(item => item.name !== "Guest" && item.name !== "HOD" && item.name !== "MD");
        setTableList(data);
    };

    useEffect(() => {
        loadTables();
    }, []);

    let loadTableNumbers = async (tableName) => {
        let {data} = await tableListFxn();
        let filtered = {};
        if (tableName) {
            let filteredArray = data.filter((item) => item.name === tableName);
            if (filteredArray.length > 0) {
                filtered = filteredArray[0];
            }
        }
        if (filtered.tables) {
            let allTableNumber = filtered.tables;
            let emptyTables = allTableNumber.filter((item) => !item.orderNo);
            setTableNumbers(emptyTables);
        }
    };
    useEffect(() => {
        if (tableName) {
            loadTableNumbers(tableName);
        }
    }, [tableName]);

    let [transferData, setTransferData] = useState({});

    let transferTable = async (orderId, tableNumber) => {
        if (!orderId) {
            notification.warning({
                message: "Order Not Found",
            });
            return;
        }
        transferData.orderId = orderId;
        transferData.tableNo = tableNumber;
        transferData.transferDetails = `Transfered to ${tableNumber}`
        let x = await dispatch(TransferTableFxn(transferData));
        if (x && x.success == true) {
            setisTransferTableModalOpen(false);
            setTableKey(new Date().toString())
            navigate(`/pos/${tableNumber}`);
        }
    };

    return (
        <>
            <PageHeader>
                <div key={tableKey}>
                    <div className="row d-flex">
                        <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7 tabs_wrapper">
                            <div className="page-header ">
                                <div className="page-title" style={{flexDirection: "column"}}>
                                    <h4>Categories</h4>
                                    <h6>Manage your purchases</h6>
                                </div>
                                <div className={""}>
                                    <div className={" d-flex gap-2"}>
                                        {category.map((item) => {
                                            return (
                                                <div
                                                    key={item.key}
                                                    className={`${
                                                        menuName === item.key ? "selected thm-btn-active" : ""
                                                    }  px-3 py-2 thm-btn `}
                                                    onClick={() => {
                                                        setMenuName(item.key);
                                                        setActiveIndex(0);
                                                        handleReset()
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <ul className={"tabs dd"} key={refreshKey}>
                                {productList && productList.length ? (
                                    <OwlCarousel
                                        lazyContent={true}
                                        key={activeIndex}
                                        className="owl-carousel owl-theme owl-product border-0 pt-2"
                                        {...options}
                                    >
                                        {productList.map((item, index) => {
                                            return (
                                                <li
                                                    className={index == activeIndex ? "active" : ""}
                                                    id={item.name}
                                                    key={item.productId}
                                                    onClick={() => {
                                                        setActiveIndex(index)
                                                        handleReset()
                                                    }}
                                                >
                                                    <div className="product-details">
                                                        {item.categoryFile && item.categoryFile.path ? (
                                                            <img
                                                                src={RenderImage(item.categoryFile.path)}
                                                                alt="img"
                                                            />
                                                        ) : null}
                                                        <h6 className={"align_center"}
                                                            style={{textTransform: "capitalize"}}>{item.name ? item.name.toLowerCase() : ""}</h6>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </OwlCarousel>
                                ) : null}
                            </ul>

                            {productList && productList.length ? (
                                <div className="tabs_container" key={activeIndex}>
                                    {productList.map((item, index) => {
                                        return (
                                            <div
                                                className={`tab_content ${
                                                    activeIndex == index ? "active" : ""
                                                }`}
                                                data-tab={item.name}
                                                key={item.productId}
                                            >
                                                <InputBox title={"Search"}>
                                                    <div className="d-flex gap-3">
                                                        <input
                                                            className="w-50"
                                                            type="text"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onKeyPress={handleKeyPress}
                                                        />
                                                        <button
                                                            className="btn btn-info"
                                                            onClick={handleSearch}
                                                        >
                                                            🔎 Search
                                                        </button>
                                                        <button
                                                            className="px-4 thm-btn-active border-0 px-4"
                                                            onClick={handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </InputBox>
                                                <div className="d-flex flex-wrap gap-1 gap-remove ">
                                                    {item && filteredProducts.length == 0 && item.product
                                                        ? item.product.map((eachPro) => {
                                                            return (
                                                                <div
                                                                    style={{width: 140}}
                                                                    className={`d-flex ${
                                                                        clickedProId == eachPro._id
                                                                            ? "clickedPro"
                                                                            : ""
                                                                    }`}
                                                                    key={eachPro._id}
                                                                    onClick={() => {
                                                                        seClickedProId(eachPro._id);
                                                                        if (currentStatus == "Done") {
                                                                            notification.warning({
                                                                                message:
                                                                                    "Order is Already Saved Cannot Add Kot",
                                                                            });
                                                                            return;
                                                                        }

                                                                        if (
                                                                            eachPro.variants.length == 0 ||
                                                                            eachPro.variants.length == 1
                                                                        ) {
                                                                            events.addProduct({
                                                                                ...eachPro,
                                                                                productId: eachPro._id,
                                                                            });
                                                                        } else {
                                                                            setVariantData({
                                                                                ...eachPro,
                                                                            });
                                                                            setIsModalOpen(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="productset flex-fill">
                                                                        <div className="productsetimg">
                                                                            {eachPro &&
                                                                            eachPro.productFile &&
                                                                            eachPro.productFile.path && (
                                                                                <img
                                                                                    src={RenderImage(
                                                                                        eachPro.productFile.path
                                                                                    )}
                                                                                    alt="img"
                                                                                />
                                                                            )}
                                                                            {eachPro &&
                                                                            eachPro.productFile &&
                                                                            eachPro.productFile.fileName && (
                                                                                <div className="check-product">
                                                                                    <i className="fa fa-check"></i>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="productsetcontent">
                                                                            <h4>{eachPro.name}</h4>
                                                                            <h6>Rs. {eachPro.price}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null}
                                                    {filteredProducts
                                                        ? filteredProducts.map((eachPro) => {
                                                            return (
                                                                <div
                                                                    style={{width: 140}}
                                                                    className={`d-flex ${
                                                                        clickedProId == eachPro._id
                                                                            ? "clickedPro"
                                                                            : ""
                                                                    }`}
                                                                    key={eachPro._id}
                                                                    onClick={() => {
                                                                        seClickedProId(eachPro._id);
                                                                        if (currentStatus == "Done") {
                                                                            notification.warning({
                                                                                message:
                                                                                    "Order is Already Saved Cannot Add Kot",
                                                                            });
                                                                            return;
                                                                        }

                                                                        if (
                                                                            eachPro.variants.length == 0 ||
                                                                            eachPro.variants.length == 1
                                                                        ) {
                                                                            events.addProduct({
                                                                                ...eachPro,
                                                                                productId: eachPro._id,
                                                                            });
                                                                        } else {
                                                                            setVariantData({
                                                                                ...eachPro,
                                                                            });
                                                                            setIsModalOpen(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="productset flex-fill">
                                                                        <div className="productsetimg">
                                                                            {eachPro &&
                                                                            eachPro.productFile &&
                                                                            eachPro.productFile.path && (
                                                                                <img
                                                                                    src={RenderImage(
                                                                                        eachPro.productFile.path
                                                                                    )}
                                                                                    alt="img"
                                                                                />
                                                                            )}
                                                                            {eachPro &&
                                                                            eachPro.productFile &&
                                                                            eachPro.productFile.fileName && (
                                                                                <div className="check-product">
                                                                                    <i className="fa fa-check"></i>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="productsetcontent">
                                                                            <h4>{eachPro.name}</h4>
                                                                            <h6>Rs. {eachPro.price}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}

                            {/* MODAL For Choose Variant */}

                            {isModalOpen && (
                                <Modal
                                    visible={isModalOpen}
                                    onClose={() => {
                                        setIsModalOpen(false);
                                        setBgIndex();
                                        seClickedProId("");
                                    }}
                                    title={variantData.name}
                                >
                                    <Card>
                                        <div className={"rest-table-box"}>
                                            <div className={"tab-header"}>
                                                <span>Variants</span>
                                            </div>
                                            <div className={"table-list"}>
                                                {variantData?.variants?.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={`tab-box mb-0`}
                                                        style={{
                                                            background: bgIndex === index ? "#FF9F43" : "",
                                                            color: bgIndex === index ? "white" : "",
                                                        }}
                                                        onClick={() => {
                                                            setBgIndex(index);
                                                            let obj = {...variantData};
                                                            obj.price = item.price;
                                                            obj.variant = item.name;
                                                            setVariantData(obj);
                                                            // setSelectedProducts(...selectedProducts,obj)
                                                        }}
                                                    >
                                                        <p className="mb-0">{item.name}</p>
                                                        <p className="mb-0">Rs. {item.price}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <button
                                                className="btn btn-danger ms-2"
                                                onClick={() => {
                                                    seClickedProId("");

                                                    setBgIndex();
                                                    setIsModalOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-success"
                                                onClick={() => {
                                                    if (!variantData.variant) {
                                                        notification.warning({
                                                            message: "Please Choose Varient",
                                                        });
                                                        return;
                                                    } else {
                                                        setBgIndex();
                                                        setIsModalOpen(false);
                                                        delete variantData.variants;
                                                        events.addProduct(variantData);
                                                    }
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Card>
                                </Modal>
                            )}
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 ">
                            <div className="order-list mb-2">
                                <div className="orderid">
                                    <h4>Order List</h4>
                                </div>
                                {orderId && !guest ? (
                                    <div className="actionproducts">
                                        <a
                                            className={"btn btn-adds xs-btn"}
                                            onClick={() => setisTransferTableModalOpen(true)}
                                        >
                                            Transfer Table
                                        </a>
                                    </div>
                                ) : null}
                                <div className="actionproducts">
                                    <a
                                        className={"btn btn-adds xs-btn"}
                                        onClick={() => events.showCustomerModal()}
                                    >
                                        {" "}
                                        <i className="fa fa-plus me-2"></i> Add Customer
                                    </a>
                                </div>
                                {/* )} */}
                            </div>
                            <div className="card card-order mt-2 mb-0 ">
                                <div className="totalitem pt-3 spaceRemove">
                                    <h4
                                        className="btn btn-link"
                                        onClick={() => {
                                            setIsOldkotModalOpen(true);
                                        }}
                                    >
                                        <smal style={{fontSize: 11, color: "black"}}>
                                            {" "}
                                            Order Total Kots : {noOfKots}
                                            {" | "}
                                        </smal>
                                        Items : {selectedProducts.length + totalProductsLength}
                                        &nbsp;
                                    </h4>
                                    <h4>
                                        <strong className="text-uppercase">{tableNo}</strong>
                                    </h4>

                                    {customerName && (
                                        <>
                                            <h4>
                                                <strong className="text-uppercase">
                                                    {customerName}&nbsp;({customerMobile})
                                                </strong>
                                                <i
                                                    className="clearButton"
                                                    onClick={() => {
                                                        setCustomerMobile("");
                                                        setCustomerName("");
                                                        setCouponCode("");
                                                        setdiscountper("");
                                                        setDisCouponCode("");
                                                        setDiscountCoupon("");
                                                    }}
                                                >
                                                    ❌
                                                </i>
                                            </h4>
                                        </>
                                    )}
                                </div>
                                <div className="dark-background">
                                    <h7>New kot</h7>
                                </div>
                                <div className="card-body pt-0 height">
                                    <div>
                                        {selectedProducts && selectedProducts.length
                                            ? selectedProducts.map((item) => {
                                                // console.log(item, "this is product");

                                                return (
                                                    <ul
                                                        className="product-lists mb-0 p-1"
                                                        key={item.productId}
                                                    >
                                                        <li className="w-50 p-1">
                                                            <div className="productimg">
                                                                <div className="productcontet">
                                                                    <h4>
                                                                        {item.variant === "Veg" ||
                                                                        item.variant === "Non Veg" ||
                                                                        item.variant === "PRAWNS"
                                                                            ? getVegStatus(item.variant)
                                                                            : getVegStatus(item.vegNonVeg)}

                                                                        {item.name}
                                                                        {item.variant && ` (${item.variant})`}
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            className="ms-2"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#edit"
                                                                        >
                                                                            <img
                                                                                src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/edit-5.svg"
                                                                                alt="img"
                                                                            />
                                                                        </a>
                                                                    </h4>
                                                                    <div className="row">
                                                                        <div className="col-3 productlinkset">
                                                                            <h5>
                                                                                <br/>
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <p
                                                                                onClick={() => {
                                                                                    setIsSpecialNoteModalOpen(true);
                                                                                    setProductIds(item.productId);
                                                                                    setvarient(item.variant);
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Image}
                                                                                    alt="img"
                                                                                    style={{
                                                                                        height: "15px",
                                                                                        width: "25px",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <p>{item.specialNote}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="increment-decrement">
                                                                <div className="input-groups">
                                                                    <input
                                                                        type="button"
                                                                        value="-"
                                                                        onClick={() => {
                                                                            events.descQty(item);
                                                                        }}
                                                                        className="button-minus dec button"
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        name="child"
                                                                        value={item.quantity}
                                                                        className="quantity-field"
                                                                    />
                                                                    <input
                                                                        type="button"
                                                                        value="+"
                                                                        onClick={() => {
                                                                            events.incQty(item);
                                                                        }}
                                                                        className="button-plus inc button "
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            <strong>Rs. {item.totalAmount}</strong>
                                                            <br/>(
                                                            <span style={{fontSize: "80%"}}>
                                Rs. {item.price}
                              </span>
                                                            )
                                                        </li>
                                                    </ul>
                                                );
                                            })
                                            : null}
                                    </div>
                                </div>
                                <div className="split-card"></div>
                                <div className="card-body pt-0 pb-2 amount-box-inner">
                                    <div className="setvalue">
                                        <ul>
                                            <li>
                                                <h5>Sub Total </h5>
                                                <h6>Rs. {state.subTotal}</h6>
                                            </li>
                                            {userType != "captain" && (
                                                <li>
                                                    <div className="d-flex">
                                                        <h5
                                                            className="fake-link"
                                                            onClick={() => {
                                                                setIsDiscountModalOpen(true);
                                                            }}
                                                        >
                                                            Apply Discount / Coupon
                                                        </h5>
                                                        {discountper && (
                                                            <h5>
                                                                -{discountper || 0}% {couponCode}{" "}
                                                                <i
                                                                    className="clearButton"
                                                                    onClick={() => {
                                                                        setCouponCode("");
                                                                        setdiscountper("");
                                                                        setDisCouponCode("");
                                                                        setDiscountCoupon("");
                                                                    }}
                                                                >
                                                                    ❌
                                                                </i>
                                                            </h5>
                                                        )}
                                                    </div>

                                                    <h6>Rs. {state.discountAmount}</h6>
                                                </li>
                                            )}
                                            <li>
                                                <div className="d-flex">
                                                    <h5>Service Tax (5%)</h5>{" "}
                                                    <input
                                                        className="ms-2"
                                                        type="checkbox"
                                                        checked={serviceTaxIncluded}
                                                        onChange={handleCheckboxChange}
                                                        disabled={userType === "captain"}
                                                    />
                                                </div>
                                                <h6>Rs. {state.serviceTaxAmount}</h6>
                                            </li>
                                            <li>
                                                <h5>CGST (2.5%) </h5>
                                                <h6>Rs. {state.cgstAmount}</h6>
                                            </li>
                                            <li>
                                                <h5>SGST (2.5%) </h5>
                                                <h6>Rs. {state.sgstAmount}</h6>
                                            </li>

                                            <li className="total-value">
                                                <h5>Total </h5>
                                                <h6>Rs. {state.netAmount}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    {currentStatus ? (
                                        <>
                                            {currentStatus == "Pending" ? buttonComponent : finalbutton}
                                        </>
                                    ) : (
                                        buttonComponent
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {customerState.visible ? (
                        <AddCustomerDrawerPos
                            onClose={events.hide}
                            onSubmit={(data) => {
                                events.hide();
                                setCustomerMobile(data.mobileNo);
                                setCustomerName(data.name);
                            }}
                            visible={customerState.visible}
                        />
                    ) : null}
                    <div style={{display: "none"}}>
                        {singleOrderData && singleOrderData._id ? (
                            <PrintDesign ref={componentRef} singleOrderData={singleOrderData}/>
                        ) : null}
                    </div>
                    {isPaymentModalOpen && (
                        <Modal
                            visible={isPaymentModalOpen}
                            onClose={() => {
                                setIsPaymentModalOpen(false);
                            }}
                            width={"35%"}
                            title={"Payment"}
                        >
                            <h6 className={"mb-2"}>
                                Order Number: {paymentData.orderNo}, Amount To Paid: Rs.{" "}
                                {paymentData.totalAmount}
                                <br/>
                            </h6>
                            <InputBox title={"Cash Discount"}>
                                <input
                                    value={paymentData.cashDiscount}
                                    className={"form-control"}
                                    type={"number"}
                                    onChange={(e) => {
                                        setPaymentData({
                                            ...paymentData,
                                            cashDiscount: e.target.value,
                                        });
                                    }}
                                />
                            </InputBox>
                            <h6>Final Amount: Rs. {paymentData.finalAmount}</h6>
                            <br/>
                            <InputBox title={"Payment Methods"}>
                                <div className="form-check form-check-inline">
                                    <label onClick={() => handlePaymentMethodChange("card")}>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="card"
                                            className="form-check-input"
                                            checked={paymentMethod === "card"}
                                        />
                                        Card
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label onClick={() => handlePaymentMethodChange("cash")}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="paymentMethod"
                                            value="cash"
                                            checked={paymentMethod === "cash"}
                                        />
                                        Cash
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label onClick={() => handlePaymentMethodChange("upi")}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="paymentMethod"
                                            value="upi"
                                            checked={paymentMethod === "upi"}
                                        />
                                        UPI
                                    </label>
                                </div>
                            </InputBox>
                            <InputBox title={"Customer Paid"}>
                                <input
                                    value={paymentData.customerPaid}
                                    className={"form-control"}
                                    type={"text"}
                                    onChange={(e) => {
                                        setPaymentData({
                                            ...paymentData,
                                            customerPaid: e.target.value,
                                        });
                                    }}
                                />
                            </InputBox>
                            <InputBox title={"Return To Customer"}>
                                <input
                                    value={paymentData.returnToCustomer}
                                    className={"form-control"}
                                    disabled
                                    type={"number"}
                                />
                            </InputBox>
                            <InputBox title={"Tip"}>
                                <input
                                    value={paymentData.tip}
                                    className={"form-control"}
                                    type={"text"}
                                    onChange={(e) => {
                                        setPaymentData({
                                            ...paymentData,
                                            tip: e.target.value,
                                        });
                                    }}
                                />
                            </InputBox>
                            <InputBox title={"Settlement Amount"}>
                                <input
                                    value={paymentData.setteledAmount}
                                    className={"form-control"}
                                    type={"number"}
                                    disabled
                                />
                            </InputBox>
                            <div className={"mt-3 d-md-flex justify-content-md-end"}>
                                <button
                                    className={"btn btn-danger"}
                                    onClick={() => {
                                        setIsPaymentModalOpen(false);
                                        setPaymentData({});
                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={() => {
                                        handlePayment();
                                    }}
                                    className={"btn btn-success ms-2"}
                                >
                                    Save
                                </button>
                            </div>
                        </Modal>
                    )}
                    {isDiscountModalOpen && (
                        <Modal
                            visible={isDiscountModalOpen}
                            onClose={() => {
                                setdiscountper(0);
                                setIsDiscountModalOpen(false);
                            }}
                            width={"30%"}
                            title={"Discount"}
                        >
                            <InputBox title={"Discount Type"}>
                                <div className="form-check form-check-inline">
                                    <label onClick={() => handleDiscountChange("discount")}>
                                        <input
                                            type="radio"
                                            name="discount"
                                            value="discount"
                                            className="form-check-input"
                                            checked={discountType === "discount"}
                                        />
                                        Discount
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label onClick={() => handleDiscountChange("coupon")}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="coupon"
                                            value="coupon"
                                            checked={discountType === "coupon"}
                                        />
                                        Coupon
                                    </label>
                                </div>
                            </InputBox>
                            {discountType == "discount" ? (
                                <InputBox title={"Discount %"}>
                                    <input
                                        value={discountper}
                                        className={"form-control w-50"}
                                        type={"number"}
                                        onChange={(e) => {
                                            setdiscountper(e.target.value);
                                        }}
                                    />
                                </InputBox>
                            ) : (
                                <InputBox title={"Coupon Code"}>
                                    <input
                                        value={disCouponCode}
                                        className={"form-control w-50"}
                                        type={"text"}
                                        onChange={(e) => {
                                            setDisCouponCode(e.target.value);
                                        }}
                                    />
                                </InputBox>
                            )}
                            <div className={"mt-3 d-md-flex justify-content-md-end"}>
                                <button
                                    className={"btn btn-danger"}
                                    onClick={() => {
                                        setdiscountper(0);
                                        setIsDiscountModalOpen(false);
                                    }}
                                >
                                    Cancel
                                </button>

                                {discountType === "coupon" ? (
                                    <button
                                        onClick={() => {
                                            setDiscountCoupon(disCouponCode);
                                            setIsDiscountModalOpen(false);
                                        }}
                                        className={"btn btn-success ms-2"}
                                    >
                                        Apply
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSaveButtonClick}
                                        className={"btn btn-success ms-2"}
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </Modal>
                    )}

                    {isSpecialNoteModalOpen && (
                        <Modal
                            visible={isSpecialNoteModalOpen}
                            onClose={() => {
                                setIsSpecialNoteModalOpen(false);
                            }}
                            width={"30%"}
                            title={"Special Note"}
                        >
                            <Form>
                                <div className="form-check form-check-inline">
                                    <InputBox title={"Select Special Note"}>
                                        <select
                                            style={{height: "45px"}}
                                            className="form-control"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            required="true"
                                        >
                                            <option value="">Select Special Note</option>
                                            {specialNoteList.map((val) => (
                                                <option key={val._id} value={val.specialNote}>
                                                    {val.specialNote}
                                                </option>
                                            ))}
                                        </select>
                                    </InputBox>
                                </div>

                                <div className={" d-md-flex justify-content-md-end"}>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => handleAddNote()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        </Modal>
                    )}

                    {isOldkotModalOpen && (
                        <Modal
                            visible={isOldkotModalOpen}
                            onClose={() => {
                                setIsOldkotModalOpen(false);
                            }}
                            width={"30%"}
                            title={"Old Kots"}
                        >
                            <div className="card-body pt-0 height">
                                <div>
                                    {OldKots && OldKots.length
                                        ? OldKots.map((item) => {
                                            const itemTime = moment(item.time);
                                            const currentTime = moment();
                                            const timeDifference = currentTime.diff(
                                                itemTime,
                                                "minutes"
                                            );
                                            return (
                                                <div key={item.kotNo}>
                                                    <div
                                                        className="dark-background"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <h7>
                                                            Kot - {item.kotNo} Time -{" "}
                                                            {moment(item.time).format("hh:mm A")}{" "}
                                                            {/* time taken by order to complete --> */}
                                                            {/* {item.status === "Done" &&
                                  `Time Taken - ${moment
                                    .utc(parseInt(item.timer) * 1000)
                                    .format("mm:ss")}`} */}
                                                        </h7>
                                                        {/* {timeDifference < 2 &&
                            currentStatus == "Pending" ? (
                              <i
                                className="fa fa-edit far"
                                onClick={() => {
                                  if (timeDifference < 2) {
                                    setEditKotModal(true);
                                    setKotId(item._id);
                                  } else {
                                    notification.warning({
                                      message: "Cannot Edit After 2 Minutes",
                                    });
                                  }
                                }}
                              ></i>
                            ) : null} */}
                                                        {currentStatus ? (
                                                            <>
                                                                {userType == "admin" ||
                                                                userType == "cashier" ? (
                                                                    <i
                                                                        className="fa fa-edit far"
                                                                        onClick={() => {
                                                                            setEditKotModal(true);
                                                                            setKotId(item._id);
                                                                        }}
                                                                    ></i>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            buttonComponent
                                                        )}
                                                    </div>

                                                    {editKotModal && (
                                                        <Modal
                                                            visible={editKotModal}
                                                            onClose={() => {
                                                                setEditKotModal(false);
                                                            }}
                                                            title={"Edit Kot"}
                                                        >
                                                            <div className={"mt-2"}>
                                                                {editKotProducts &&
                                                                editKotProducts.map((product) => {
                                                                    return (
                                                                        <>
                                                                            <ul
                                                                                className="product-lists mb-0 p-1"
                                                                                key={product._id}
                                                                            >
                                                                                <li className="w-50">
                                                                                    <div className="productimg">
                                                                                        <div
                                                                                            className="productcontet">
                                                                                            <h6 className={"mb-0"}>
                                                                                                {product &&
                                                                                                product.productId &&
                                                                                                product.productId
                                                                                                    .vegNonVeg ? (
                                                                                                    <>
                                                                                                        {getVegStatus(
                                                                                                            product.productId
                                                                                                                .vegNonVeg
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                                {product.name}{" "}
                                                                                                {product.variant &&
                                                                                                ` (${product.variant})`}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>

                                                                                <li>
                                                                                    <div
                                                                                        className="increment-decrement">
                                                                                        <div
                                                                                            className="input-groups">
                                                                                            <input
                                                                                                type="button"
                                                                                                value="-"
                                                                                                onClick={() => {
                                                                                                    events.desEditcQty(product);
                                                                                                }}
                                                                                                className="button-minus dec button"
                                                                                            />
                                                                                            <input
                                                                                                type="text"
                                                                                                name="child"
                                                                                                value={product.quantity}
                                                                                                className="quantity-field"
                                                                                            />
                                                                                            <input
                                                                                                type="button"
                                                                                                value="+"
                                                                                                onClick={() => {
                                                                                                    events.incEditQty(product);
                                                                                                }}
                                                                                                className="button-plus inc button "
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                            <span
                                                style={{
                                                    fontSize: "80%",
                                                }}
                                            >
                                              Rs. {product.price}
                                            </span>
                                                                                </li>
                                                                                <li>
                                                                                    <strong>
                                                                                        Rs. {product.totalAmount}
                                                                                    </strong>
                                                                                </li>
                                                                            </ul>
                                                                        </>
                                                                    );
                                                                })}

                                                                <div className="d-flex justify-content-end mt-2">
                                                                    <button
                                                                        onClick={() => {
                                                                            handleEditkot(editKotProducts, "kot");
                                                                        }}
                                                                        className="btn btn-success"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    )}

                                                    {item.products.map((product) => {
                                                        // console.log(product, "this is product");

                                                        return (
                                                            <>
                                                                <ul
                                                                    className="product-lists mb-0 p-1"
                                                                    key={product._id}
                                                                >
                                                                    <li className="w-50">
                                                                        <div className="productimg">
                                                                            <div className="productcontet">
                                                                                <h4 className={"mb-0"}>
                                                                                    {product &&
                                                                                    product.productId &&
                                                                                    product.productId.vegNonVeg ? (
                                                                                        <>
                                                                                            {getVegStatus(
                                                                                                product.productId.vegNonVeg
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {product.name}{" "}
                                                                                    {product.variant &&
                                                                                    ` (${product.variant})`}
                                                                                </h4>

                                                                                <div className="d-flex">
                                                                                    {product.status == "Pending" ? (
                                                                                        <div className="productlinkset">
                                                                                            <h5>
                                                                                                {" "}
                                                                                                <br/>
                                                                                            </h5>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="blue-color">
                                                                                            <h5>
                                                                                                {" "}
                                                                                                <br/>
                                                                                            </h5>
                                                                                        </div>
                                                                                    )}

                                                                                    {product.priority == "Low" &&
                                                                                    product.status == "Pending" ? (
                                                                                        <i
                                                                                            onClick={() => {
                                                                                                events.updatePriority(
                                                                                                    item,
                                                                                                    product.productId._id
                                                                                                );
                                                                                            }}
                                                                                            className="buzz"
                                                                                        >
                                                                                            ⚡
                                                                                        </i>
                                                                                    ) : (
                                                                                        product.status == "Pending" && (
                                                                                            <p className="p-high">High</p>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <div className="increment-decrement">
                                                                            <div className="input-groups gap-1">
                                                                                <h7>Qty:</h7>
                                                                                <input
                                                                                    type="text"
                                                                                    name="child"
                                                                                    value={product.quantity}
                                                                                    className="quantity-field"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <strong>Rs. {product.totalAmount}</strong>
                                                                        <br/>(
                                                                        <span style={{fontSize: "80%"}}>
                                      Rs. {product.price}
                                    </span>
                                                                        )
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })
                                        : null}
                                </div>
                            </div>
                        </Modal>
                    )}

                    {isTransferTableModalOpen && (
                        <Modal
                            visible={isTransferTableModalOpen}
                            onClose={() => {
                                setisTransferTableModalOpen(false);
                            }}
                            width={"35%"}
                            title={"Transfer Table"}
                        >
                            <Form>
                                <div className="form-check form-check-inline">
                                    <InputBox title={"Select Table"}>
                                        <select
                                            style={{height: "45px", width: "200px"}}
                                            className="form-control"
                                            value={tableName}
                                            onChange={(e) => setTableName(e.target.value)}
                                            required="true"
                                        >
                                            <option value="">Select Table</option>
                                            {tableList.map((val) => (
                                                <option key={val.name} value={val.name}>
                                                    {val.name}
                                                </option>
                                            ))}
                                        </select>
                                    </InputBox>
                                </div>
                                <div className="form-check form-check-inline">
                                    <InputBox title={"Select Table Number"}>
                                        <select
                                            style={{height: "45px", width: "200px"}}
                                            className="form-control"
                                            value={tableNumber}
                                            onChange={(e) => setTableNumber(e.target.value)}
                                            required="true"
                                        >
                                            <option value="">Select Table Number</option>
                                            {tableNumbers.map((val) => (
                                                <option key={val.name} value={val.key}>
                                                    {val.name}
                                                </option>
                                            ))}
                                        </select>
                                    </InputBox>
                                </div>

                                <div className={" d-md-flex justify-content-md-end"}>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => transferTable(orderId, tableNumber)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        </Modal>
                    )}

                </div>

            </PageHeader>
        </>
    );
};
export default PosComponent;
