import React, {useState, useEffect} from "react";
import moment from "moment";
import {getTableName} from "../../components/_utils/_utils";

export class KotPrintDesign extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let {kotRecord} = this.props;
        return (
            <div className="ml-4" id="printableDiv">
                {kotRecord && kotRecord._id ? (
                    <div className="subcontainer">
                        <h6
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            {moment(kotRecord.date).format("DD/MM/YYYY HH:mm")}{" "}
                        </h6>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            KOT - {kotRecord.kotNo}
                        </p>
                        {/* <p style={{textAlign: "center", fontSize: "11px",fontWeight: "bold"}}>Dine In</p> */}
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            <div>
                                Table No: {getTableName(kotRecord.tableNo)}
                            </div>
                            {kotRecord.guest
                                ? `Guest - ${
                                    kotRecord.customerName ? kotRecord.customerName : "---"
                                }`
                                : <>
                                    {kotRecord.customerName ? kotRecord.customerName : null}
                                </>
                            }
                        </p>

                        <div className="table-kot-design mt-2">
                            <table className="table">
                                <tr>
                                    <th scope="col" style={{fontWeight: "bold"}}>
                                        Item
                                    </th>
                                    <th
                                        scope="col"
                                        style={{fontWeight: "bold"}}
                                        className="table-heading"
                                    >
                                        Special Note
                                    </th>
                                    <th scope="col" style={{fontWeight: "bold"}}>
                                        Qty.
                                    </th>
                                </tr>
                                {kotRecord.products?.map((item, index) => {
                                    let key = item._id + "-" + index;
                                    console.log(item, "abc");
                                    return (
                                        <>
                                            <tr key={key}>
                                                <td
                                                    style={{
                                                        fontWeight: "bold",
                                                        lineHeight: "1rem",
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    {item.productId.name}
                                                    {item.variant ? " - " + item.variant : ""}{" "}
                                                    {item.specialNote ? " - " + item.specialNote : ""}
                                                </td>
                                                <td style={{fontWeight: "bold"}}>---</td>
                                                <td style={{fontWeight: "bold", fontSize: "18px"}}>
                                                    {item.quantity}
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </table>
                            <div
                                className="divider"
                                style={{borderBottom: "2px solid black"}}
                            ></div>
                        </div>
                        <div style={{pageBreakBefore: "always"}}></div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default KotPrintDesign;
