import AddCategory from "../containers/categories/addCategory";
import EditCategory from "../containers/categories/editCategory";
import AddCoupon from "../containers/coupon/addCoupon";
import EditCoupon from "../containers/coupon/editCoupon";
import AddCustomer from "../containers/customer/addCustomer";
import CustomerList from "../containers/customer/customerList";
import EditCustomer from "../containers/customer/editCustomer";
import Dashboard from "../containers/dashboard/dashboardComponent";
import KotData from "../containers/kot/kotList";
import KotView from "../containers/kotView";
import KotViewTest from "../containers/kotViewNew";
import OrderList from "../containers/order/orderList";
import GuestOrderList from "../containers/order/guestOrderList";
import PosTables from "../containers/pos/views/posTables";
import KotPrintDesign from "../containers/print/kotPrint";
import PrintDesign from "../containers/print/printDesign";
import PrintTesting from "../containers/printTesting";
import AddProduct from "../containers/products/addProduct";
import AddProductNew from "../containers/products/addProductNew";
import EditProduct from "../containers/products/editProduct";
import ProductData from "../containers/products/productList";
import AddSale from "../containers/sale/addSale";
import AddSaleProduct from "../containers/sale/addSaleProduct";
import PrintSale from "../containers/sale/printSale";
import SaleList from "../containers/sale/saleList";
import SaleProductList from "../containers/sale/saleProductList";
import AddVariant from "../containers/variants/addVariants";
import EditVariant from "../containers/variants/editVariant";
import Pos from "../containers/pos/views/pos";
import EditSpecialNote from "../containers/editSpecialNote";
import SpecialNote from "../containers/specialNote";
import ChangePassword from "../containers/changepassword/changePassword";
import AddUser from "../containers/userManager/addUser";
import UserList from "../containers/userManager/userList";
import {kitchenTypes} from "../components/Elements/appUtils";
import EditUser from "../containers/userManager/editUser";

let menuData = [
    {
        name: "Dashboard",
        path: "/dashboard",
        key: "dashboard",
        icon: "monitor",
        component: Dashboard,
        // authority: ["admin", "cashier", "account",...kitchenTypes],
    },
    {
        name: "editCategory",
        path: "/editCategory",
        key: "editCategory",
        icon: "monitor",
        component: EditCategory,
        dontShowOnMenu: true,
    },
    {
        name: "Change Password",
        path: "/changePassword",
        key: "changePassword",
        icon: "monitor",
        component: ChangePassword,
        dontShowOnMenu: true,
    },
    {
        name: "editSpecialNote",
        path: "/editSpecialNote",
        key: "editSpecialNote",
        icon: "monitor",
        component: EditSpecialNote,
        dontShowOnMenu: true,
    },
    {
        name: "Special Note",
        path: "/specialNote",
        key: "specialNote",
        icon: "monitor",
        component: SpecialNote,
        authority: ["admin", "account"],
    },
    {
        name: "editProduct",
        path: "/editProduct",
        key: "editProduct",
        icon: "monitor",
        component: EditProduct,
        dontShowOnMenu: true,
    },
    {
        name: "editUser",
        path: "/editUser",
        key: "editUser",
        icon: "monitor",
        component: EditUser,
        dontShowOnMenu: true,
    },
    {
        name: "Kot Print",
        path: "/kot-print",
        key: "kotPrint",
        icon: "monitor",
        component: KotPrintDesign,
        dontShowOnMenu: true,
    },
    {
        name: "Kot View",
        path: "/KotView",
        key: "KotView",
        icon: "monitor",
        component: KotView,
        authority: ["admin", "cashier", ...kitchenTypes],
    },
    {
        name: "Kot View test",
        path: "/KotViewTest",
        key: "KotViewTest",
        icon: "monitor",
        component: KotViewTest,
        authority: ["admin", "cashier", ...kitchenTypes],
        dontShowOnMenu: true
    },
    {
        name: "User Management",
        icon: "user",
        key: "user",
        authority: ["admin","cashier"],
        subMenu: [
            {
                name: "Add User",
                path: "/add-user",
                key: "addUser",
                component: AddUser,
            },
            {
                name: "User List",
                path: "/user-list",
                key: "userList",
                component: UserList,
            },
        ],
    },
    {
        name: "Product",
        icon: "book",
        key: "product",
        authority: ["admin"],
        subMenu: [
            {
                name: "Add Product",
                path: "/addProduct",
                key: "addProduct",
                component: AddProduct,
            },
            {
                name: "Product List",
                path: "/productList",
                key: "productList",
                component: ProductData,
            },
        ],
    },
    {
        name: "Sale",
        icon: "book",
        key: "product",
        authority: ["admin"],
        subMenu: [
            {
                name: "Add Sale",
                path: "/addSale",
                key: "addSale",
                icon: "monitor",
                component: AddSale,
            },
            {
                name: "Sale List",
                path: "/saleList",
                key: "saleList",
                icon: "monitor",
                component: SaleList,
            },
            {
                name: "Product Sale List",
                path: "/productSaleList",
                key: "productSaleList",
                icon: "monitor",
                component: SaleProductList,
            },
            {
                name: "Print Sale",
                path: "/print-sale",
                key: "print-sale",
                icon: "monitor",
                component: PrintSale,
                dontShowOnMenu: true,
            },
        ],
    },

    {
        name: "Customer",
        icon: "book",
        key: "customer",
        authority: ["admin"],
        subMenu: [
            {
                name: "Add Customer",
                path: "/add-customer",
                key: "addCustomer",
                component: AddCustomer,
            },
            {
                name: "Customer List",
                path: "/customerList",
                key: "customerList",
                icon: "monitor",
                component: CustomerList,
            },
            {
                name: "editCustomer",
                path: "/editCustomer",
                key: "editCustomer",
                icon: "monitor",
                component: EditCustomer,
                dontShowOnMenu: true,
            },
        ],
    },
    {
        name: "Tables",
        path: "/tables",
        key: "tables",
        icon: "monitor",
        component: PosTables,
        authority: ["admin", "captain", "cashier"],
    },
    {
        name: "POS",
        path: "/pos/:table",
        key: "post",
        icon: "monitor",
        component: Pos,
        dontShowOnMenu: true,
    },
    {
        name: "Coupon",
        path: "/addCoupon",
        key: "addCoupon",
        icon: "monitor",
        component: AddCoupon,
        authority: ["couponManager","admin"],
    },

    {
        name: "editCoupon",
        path: "/editCoupon",
        key: "editCoupon",
        icon: "monitor",
        component: EditCoupon,
        dontShowOnMenu: true,
    },
    {
        name: "Print",
        path: "/print",
        key: "post",
        icon: "monitor",
        component: PrintTesting,
        dontShowOnMenu: true,
    },
    {
        name: "Kot Print",
        path: "/kot-print",
        key: "kotPrint",
        icon: "monitor",
        component: KotPrintDesign,
        dontShowOnMenu: true,
    },
    {
        name: "Kot List",
        path: "/kotList",
        key: "kotList",
        icon: "monitor",
        component: KotData,
        // dontShowOnMenu: true,
        authority: ["admin", "cashier", "account"],
    },
    {
        name: "Order List",
        path: "/orderList",
        key: "orderList",
        icon: "monitor",
        component: OrderList,
        // dontShowOnMenu: true,
        authority: ["admin", "cashier", "account"],
    },

    {
        name: "Guest Orders",
        path: "/guestOrderList",
        key: "guestOrderList",
        icon: "monitor",
        component: GuestOrderList,
        // dontShowOnMenu: true,
        authority: ["admin", "account"],
    },

    {
        name: "Add Sale Product",
        path: "/addSaleProduct",
        key: "addSaleProduct",
        icon: "monitor",
        component: AddSaleProduct,
        dontShowOnMenu: true,
    },
];
let settingData = [
    {
        name: "Category",
        path: "/addCategory",
        key: "addCategory",
        icon: "monitor",
        component: AddCategory,
        authority: ["admin"],
    },
    {
        name: "Variant",
        path: "/addVariant",
        key: "addVariant",
        icon: "monitor",
        authority: ["admin"],
        component: AddVariant,
    },
    {
        name: "Edit Variant",
        path: "/editVariant",
        key: "editVariant",
        icon: "monitor",
        component: EditVariant,
        dontShowOnMenu: true,
    },
];
export {menuData, settingData};
