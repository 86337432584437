import {
    addProductUrl,
    getStockUrl,
    getTopSelling,
    singleProductUrl, updateProductStatusUrl,
    updateProductUrl,
} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import axios from "axios";
import {getToken} from "../../../request";

export const fetchProduct = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    };
    const {data} = await axios.get(getStockUrl(), config);
    return data;
};
export const fetchTopSelling = async () => {
    const {data} = await axios.get(getTopSelling(), getToken());
    return {data};
};

export const addProductFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const {data} = await axios.post(addProductUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
        notification.success({message: data.message || "Success"});
    } else {
        notification.error({message: data.message || "Error"});
    }
    return data;
};

export const singleProductFxn = (id) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.get(singleProductUrl(id), getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};
export const updateProductFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateProductUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const updateProductStatusFxn = (productId) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateProductStatusUrl(productId), {}, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
