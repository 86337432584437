import React, {useRef, useState} from "react";
// import { customAxios as axios, getToken } from "../../request";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Form,
    InputBox,
    Popconfirm,
    Table,
    TableButton,
    displayDate,
    longDisplayDate,
    notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {appAxios as axios, getToken} from "../../request";
import {deleteCouponFxn, fetchCoupon} from "./actions";
import {addCouponUrl} from "./api";

function AddCoupon(props) {
    let tableRef = useRef();
    let dispatch = useDispatch();

    const {getFieldValue} = props.form;

    const [formData, setFormData] = useState({
        name: "",
    });
    const [categoryFile, setCategoryFile] = useState("");

    const handleFileChange = (e) => {
        const file = e.files[0];
        setCategoryFile(file);
    };
    const navigate = useNavigate();
    const handleChange = (e, fieldName) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    let events = {
        deleteCoupon: async (id) => {
            let resp = await dispatch(deleteCouponFxn({couponId: id}));
            if (resp && resp.success) {
                tableRef.current.reload();
            }
        },
    };
    const handleSubmit = async (e) => {
        const {form} = props;

        e.preventDefault();
        console.log(formData, "formdata");
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (valData.discount > 25) {
                    notification.warning({message: "Discount cannot be more than 25%"});
                    return;
                }
                console.log(valData, "dddddddddddddddd");

                const response = await axios.post(addCouponUrl(), valData, getToken());
                console.log(response, "dddddddddddddddd");
                if (response.data.success) {
                    notification.success({
                        message: response.data.message || "Success",
                    });
                    props.form.setFieldsValue({
                        discount: "",
                        mobileNo: "",
                    });
                    tableRef.current.reload();
                } else {
                    notification.error({message: response.data.message});
                }
            } else {
                if (!valData.discount) {
                    notification.warning({message: "Please Enter Discount"});
                    return;
                }
                if (!valData.mobileNo) {
                    notification.warning({message: "Please Enter MobileNo"});
                    return;
                }
            }
        });

        // Send the data to the backend using Axios
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await fetchCoupon({
                    ...params,
                    regExFilters: ["couponCode", "discount", "mobileNo"],
                });
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const columns = [
        {
            title: "Coupon Code",
            dataIndex: "couponCode",
            searchTextName: "couponCode",

            key: "couponCode",
        },
        {
            title: "Discount in Percentage",
            dataIndex: "discount",
            searchTextName: "discount",

            key: "discount",
        },
        {
            title: "Mobile No",
            dataIndex: "mobileNo",
            searchTextName: "mobileNo",

            key: "mobileNo",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",

            render: (status, record) => {
                const isExpired =
                    record.expiryDate && new Date(record.expiryDate) < new Date();
                const isNotUsed = status !== "used";

                if (isExpired && isNotUsed) {
                    return "Expired";
                } else {
                    return status;
                }
            },
        },
        {
            title: "Expiry Date",
            dataIndex: "expiryDate",
            key: "expiryDate",
            render: (expiryDate) => {
                // You can format the date as needed
                return longDisplayDate(expiryDate);
            },
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => {
                // You can format the date as needed
                return longDisplayDate(createdAt);
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                return (
                    <>
                        {" "}
                        <TableButton
                            type={"edit"}
                            onClick={() => {
                                navigate(`/editCoupon?_id=${record._id}`);
                            }}
                        />
                        <Popconfirm
                            title={"Are you sure, you want to delete this Coupon?"}
                            onConfirm={() => {
                                events.deleteCoupon(record._id);
                            }}
                        >
                            <TableButton type={"close"}/>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];
    let inputTypes = {
        fields: [
            {
                key: "discount",
                label: "Discount in Percentage",
                span: "col-md-4",
                type: "number",
                required: true,
            },
            {
                key: "mobileNo",
                label: "Mobile Number",
                span: "col-md-4",
                required: true,
            },

            // {
            //   key: "status",
            //   label: "Status",
            //   span: "col-md-4",
            //   required: true,

            //   type: "select",
            //   options: ["Active", "Used", "Expired"],
            // },
        ],
    };

    return (
        <PageHeader title={"Coupons"}>
            <Form onSubmit={handleSubmit}>
                <div className="form-elements-wrapper">
                    <div className="card-body">
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div
                                        className={`${item.span ? item.span : "col-md-6"}`}
                                        key={key}
                                    >
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                    {/* <Card title={"Varients"} size={"small"}>
            <div>
              <div>
                {formData?.map((data, index) => (
                  <div key={index} className={"row"}>
                    <div className={"col-md-3"}>
                      <InputBox title={"Varients"}>
                        <select
                          className="form-control"
                          value={data.varientId}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "varientId",
                              e.target.value
                            )
                          }
                          required="true"
                        >
                          <option value="">Select Varient</option>
                          {variant?.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </InputBox>
                    </div>
                    <div className={"col-md-3"}>
                      <InputBox title={"Price"}>
                        <input
                          className={"form-control"}
                          type="number"
                          value={data.price}
                          onChange={(e) =>
                            handleInputChange(index, "price", e.target.value)
                          }
                          placeholder="Amount"
                        />
                      </InputBox>
                    </div>
                    <div className={"col-md-3"}>
                      <InputBox className="deleteBtn">
                        <div key={index} className={"item-flex mt-3"}>
                          {index > 0 &&
                            showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                              <a
                                className="empty_btn"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fa fa-trash-alt"></i>
                              </a>
                            )}
                        </div>
                      </InputBox>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <InputBox className={"pb-2"}>
                  <a className="addBtn  btn-link" onClick={handleAddField}>
                    Add More
                  </a>
                </InputBox>
              </div>
            </div>
          </Card> */}
                </div>

                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </Form>
            <div class="row mt-3">
                <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddCoupon);
