import React, {useRef, useState} from "react";
// import { customAxios as axios, getToken } from "../../request";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import {
    Form,
    Table,
    notification,
    Card,
} from "../../components/Elements/appUtils";
import {getToken} from "../../request";
import {
    KotType,
    category,
    categoryType,
} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {appAxios as axios} from "../../request";
import {fetchCategory} from "./actions";
import {addCategoryUrl} from "./api";
import Image from "../../edit (1).png";
import {FetchCatgory} from "../variants/actions";

let filterInit = {
    name: "",
    menu: "",
    categoryType: ""
}

function AddCategory(props) {
    let tableRef = useRef();
    const {getFieldValue} = props.form;
    let [filter, setFilter] = useState(filterInit)

    const [formData, setFormData] = useState({
        name: "",
    });
    const [categoryFile, setCategoryFile] = useState("");
    const [mainCategoryIndex, setMainCategoryIndex] = useState("");

    const handleFileChange = (e) => {
        const file = e.files[0];
        setCategoryFile(file);
    };
    const navigate = useNavigate();
    const handleChange = (e, fieldName) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    const handleSubmit = async (e) => {
        const {form} = props;

        e.preventDefault();
        console.log(formData, "formdata");
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.menu) {
                    notification.warning({message: "Please select menu"});
                    return;
                }
                if (!valData.name) {
                    notification.warning({message: "Enter Name"});
                    return;
                }

                valData.mainCategoryIndex = mainCategoryIndex;
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (categoryFile && categoryFile.name) {
                    fd.append("categoryFile", categoryFile);
                }

                const response = await axios.post(addCategoryUrl(), fd, getToken());
                if (response.data.success) {
                    notification.success({
                        message: response.data.message || "Success",
                    });
                    form.setFieldsValue({name: "", menu: undefined});

                    setCategoryFile("");

                    tableRef.current.reload();
                } else {
                    notification.error({message: response.data.message});
                }
            }
        });
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (filter.name) {
                    params.name = filter.name;
                }
                if (filter.menu) {
                    params.menu = filter.menu;
                }
                if (filter.categoryType) {
                    params.categoryType = filter.categoryType;
                }
                const data = await fetchCategory({...params});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };


    let filterEvent = {
        _updateFilterState: (data) => {
            setFilter((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        }
    }

    const search = async () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    };


    const columns = [
        {
            title: "Main Category",
            dataIndex: "menu",
            key: "menu",
        },
        {
            title: "Category Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Category Type",
            dataIndex: "categoryType",
            key: "categoryType",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, item) => {
                return (
                    <>
                        <a
                            onClick={() => {
                                navigate(`/editCategory?_id=${item._id}`);
                            }}
                        >
                            <img src={Image} style={{height: "20px", width: "20px"}}/>
                        </a>
                    </>
                );
            },
        },
    ];
    let inputTypes = {
        fields: [
            {
                key: "menu",
                label: "Main Category",
                span: "col-md-3",
                type: "select",
                options: category,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (selectedOption) => {
                    const [key, index] = selectedOption.split("_");
                    console.log(key, index, "index");
                    let findIndex = _.find(category, (item) => {
                        return item.name == selectedOption;
                    });
                    setMainCategoryIndex(findIndex.index);
                    props.form.setFieldsValue({
                        menu: key,
                        mainCategoryIndex: findIndex.index,
                    });
                },
            },
            {
                key: "name",
                label: "Name *",
                span: "col-md-3",
                // placeholder: "Name",
            },
            {
                key: "categoryType",
                label: "Category type",
                span: "col-md-3",
                type: "select",
                options: KotType,
                keyAccessor: (x) => x.key,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryType: x,
                    });
                },
            },
            {
                key: "categoryFile",
                label: "Category File",
                required: false,
                type: "file",
                fileName: categoryFile,
                onChange: ({target}) => {
                    handleFileChange(target);
                },
                span: "col-md-3",
            },
        ],
    };
    return (
        <PageHeader title={"Categories"}>
            <Form onSubmit={handleSubmit}>
                <div className="form-elements-wrapper">
                    <div className="card-body">
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div
                                        className={`${item.span ? item.span : "col-md-6"}`}
                                        key={key}
                                    >
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                ) : null;
                            })}
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>

                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </Form>
            <div className="card-body">
                <div className="row mt-3">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <select className="form-control search-filters"
                                        onChange={(e) => {
                                            filterEvent._updateFilterState({menu: e.target.value, categoryId: ""})
                                        }}
                                        value={filter.menu}>
                                    <option value={""}>Choose Main Category</option>
                                    {category.map((item) => {
                                        return (
                                            <option key={item.key} value={item.name}>
                                                {item.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control search-filters"
                                    name="name"
                                    placeholder="Search by Name"
                                    value={filter.name}
                                    onChange={(e) => {
                                        filterEvent._updateFilterState({name: e.target.value})
                                    }}
                                />
                            </div>

                            <div className="col-md-3">
                                <select className="form-control search-filters"
                                        onChange={(e) => {
                                            filterEvent._updateFilterState({categoryType: e.target.value})
                                        }}
                                        value={filter.categoryType}>
                                    <option value={""}>Choose Category Type</option>
                                    {KotType && KotType.length ? KotType.map((item) => {
                                        return (
                                            <option key={item.key} value={item.key}>
                                                {item.name}
                                            </option>
                                        )
                                    }) : null}
                                </select>
                            </div>


                            <div className="col-md-2">
                                <div className="search-wrap">
                                    <button className="btn btn-info" onClick={search}>
                                        Search
                                    </button>
                                </div>
                            </div>

                        </div>


                        <Card title={"Category List"}>
                            <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                        </Card>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddCategory);
