import React, {useEffect, useRef, useState} from "react";
import {Table, Tooltip, Popconfirm, DefaultTablePagination, notification} from "../../components/Elements/appUtils";
import {fetchProduct, updateProductStatusFxn} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {deleteProductUrl} from "./api";
import {updateProductStatusUrl} from "./api";
import {getToken} from "../../request";
import {category} from "../../components/_utils/appUtils";
import {FetchCatgory} from "../variants/actions";
import {useDispatch} from "react-redux";

let filterInit = {
    name: "",
    menu: "",
    categoryId: ""
}

function ProductList() {
    let dispatch = useDispatch()
    let tableRef = useRef();
    const navigate = useNavigate();
    let [filter, setFilter] = useState(filterInit)
    const [categoryList, setCategoryList] = useState([])
    const [k, setK] = useState();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParams()

            try {
                if (resp.name) {
                    params.name = resp.name;
                }
                if (resp.menu) {
                    params.menu = resp.menu;
                }
                if (resp.categoryId) {
                    params.categoryId = resp.categoryId;
                }

                const data = await fetchProduct({...params, regExFilters: ["name", "menu"]});
                resolve(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const getParams = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let categoryId = searchParams.get('categoryId')
            let name = searchParams.get('name')
            let menu = searchParams.get('menu')
            let obj = {}
            if (categoryId) {
                obj.categoryId = categoryId
            }
            if (menu) {
                obj.menu = menu
            }
            if (name) {
                obj.name = name
            }
            resolve(obj)
        })
    }

    useEffect(() => {
        filterEvent.loadCategory()
    }, [filter.menu])

    let filterEvent = {
        loadCategory: async () => {
            let obj = {
                results: 500
            }
            if (filter.menu) {
                obj.menu = filter.menu;
            }
            let {data} = await FetchCatgory(obj);
            setCategoryList(data)
        },
        _updateFilterState: (data) => {
            setFilter((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        }
    }

    const search = async () => {
        let arr = []
        if (filter.categoryId) {
            arr.push(`categoryId=${filter.categoryId}`)
        }
        if (filter.name) {
            arr.push(`name=${filter.name}`)
        }
        if (filter.menu) {
            arr.push(`menu=${filter.menu}`)
        }
        navigate(`/productList?${arr.join('&')}`)
        setTimeout(() => {
            tableRef.current.reload()
        }, 300)
    };

    const setFieldByParams = async () => {
        let resp = await getParams()
        let obj = {}
        if (resp.name) {
            obj.name = resp.name
        }
        if (resp.categoryId) {
            obj.categoryId = resp.categoryId
        }
        if (resp.menu) {
            obj.menu = resp.menu
        }
        filterEvent._updateFilterState(obj);
    }

    const deleteProduct = async (id) => {
        try {
            const response = await axios.delete(deleteProductUrl(id), getToken());

            if (response.data.error) {
                notification.error({
                    message: response.data.message,
                });
            } else {
                tableRef.current.reload();
                notification.success({
                    message: response.data.message,
                });
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const updateProductStatus = async (id) => {
        let resp = await dispatch(updateProductStatusFxn(id));
        if (resp && resp.success) {
            tableRef.current.reload();
        }

    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Main Category",
            dataIndex: "categoryId",
            key: "categoryId",
            render: (text, record, index) => {
                return (
                    <span>
            {record.categoryId && record.categoryId.menu
                ? record.categoryId.menu
                : ""}
          </span>
                );
            },
        },
        {
            title: "Category",
            dataIndex: "categoryId",
            key: "categoryId",
            render: (text, record, index) => {
                return (
                    <span>
            {record.categoryId && record.categoryId.name
                ? record.categoryId.name
                : ""}
          </span>
                );
            },
        },

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (item) => {
                return item ? (
                    <label className={"label label-info label-style"}>{item}</label>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (item) => {
                return item ? <>Rs. {item}</> : "";
            },
        },
        {
            title: "Veg/Non-Veg/Egg",
            dataIndex: "vegNonVeg",
            key: "vegNonVeg",
            render: (item) => {
                return (
                    item ? <>
                        {item == 'Veg' ?
                            <label className={"label label-warning label-style"}>{item}</label> :
                            <label className={"label label-danger label-style"}>{item}</label>}
                    </> : null
                )
            },
        },
        {
            title: "Status",
            dataIndex: "productStatus",
            key: "productStatus",
            render: (productStatus) => {
                let backgroundColor = "";
                if (productStatus === "Active") {
                    backgroundColor = "lightgreen";
                } else if (productStatus === "Inactive") {
                    backgroundColor = "lightcoral";
                }
                return (
                    <div>
                        {productStatus == 'Active' ?
                            <label className={"label label-success label-style"}>{productStatus}</label> :
                            <label className={"label label-danger label-style"}>{productStatus}</label>}
                    </div>
                );
            },
        },
        // {
        //     title: "Apply-Discount/Coupon",
        //     dataIndex: "coupon",
        //     key: "coupon",
        // },

        // {
        //     title: "Gst",
        //     dataIndex: "gst",
        //     key: "gst",
        // },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, item) => {
                return (
                    <>
                        <a className={"empty_btn"}
                           onClick={() => {
                               navigate(`/editProduct?_id=${item._id}`);
                           }}>
                            <i className={"fa fa-edit far"}/>
                        </a>

                        <Tooltip title={"Delete"} className="ml-4">
                            <Popconfirm
                                title={"Are your sure, you want to delete Product?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    deleteProduct(item._id);
                                }}>
                                <a className={'empty_btn'}>
                                    <i className={'fa fa-trash far'}/>
                                </a>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={"Status"} className="ml-4">
                            <Popconfirm
                                title={"Are your sure, you want to Update Product Status?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    updateProductStatus(item._id);
                                }}>
                                <a className={'btn btn-info btn-sm'}>
                                    Status
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {

            if (!item.hide) {
                columnArr.push(item);
                console.log(columnArr, "itemitemitem")
            }
        });
        return columnArr;
    };

    useEffect(() => {
        setFieldByParams()
    }, [])
    return (
        <PageHeader
            title={"Products"}
            extraLink={[
                {
                    name: "Add Product",
                    link: "/addproduct",
                },
            ]}
        >
            <div className="row mb-3">
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control search-filters"
                        name="name"
                        placeholder="Search by Name"
                        value={filter.name}
                        onChange={(e) => {
                            filterEvent._updateFilterState({name: e.target.value})
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <select className="form-control search-filters"
                            onChange={(e) => {
                                filterEvent._updateFilterState({menu: e.target.value, categoryId: ""})
                            }}
                            value={filter.menu}>
                        <option value={""}>Choose Main Category</option>
                        {category.map((item) => {
                            return (
                                <option key={item.key} value={item.name}>
                                    {item.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-3">
                    <select className="form-control search-filters"
                            onChange={(e) => {
                                filterEvent._updateFilterState({categoryId: e.target.value})
                            }}
                            value={filter.categoryId}>
                        <option value={""}>Choose Category</option>
                        {categoryList && categoryList.length ? categoryList.map((item) => {
                            return (
                                <option key={item.key} value={item._id}>
                                    {item.name}
                                </option>
                            )
                        }) : null}
                    </select>
                </div>


                <div className="col-md-2">
                    <div className="search-wrap">
                        <button className="btn btn-info" onClick={search}>
                            Search
                        </button>
                    </div>
                </div>

            </div>

            <div className="card mb-0">
                <div className="table-responsive">
                    <Table
                        apiRequest={apiRequest}
                        columns={getColumns()}
                        pagination={DefaultTablePagination()}

                        ref={tableRef}
                        //  pagination={{ defaultPageSize: 20 }}
                    />
                </div>
            </div>
        </PageHeader>
    );
}

export default ProductList;
