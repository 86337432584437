import React, { useEffect, useState } from "react";
import { Form, HyperLink } from "./Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "./_utils/formUtils";
import {
  States,
  gstTypeList,
  Countries,
  getStateByCountry,
} from "./_utils/appUtils";
import {
  addCustomerFxn,
  addPosCustomerFxn,
} from "../containers/customer/action";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { notification } from "./Elements/appUtils";
import ChooseCustomer from "./chooseCustomer";
import ChooseCustomerPos from "./chooseCustomerPos";

const AddCustomerComponent = (props) => {
  let { onClose = null, onSubmit } = props;

  const { getFieldValue } = props.form;
  let dispatch = useDispatch();

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }
        if (!valData.mobileNo) {
          notification.warning({ message: "Please Enter Mobile Number" });
          return;
        }
     
        let x = await dispatch(addPosCustomerFxn(valData));

        if (x && x.success) {
          if (onSubmit) {
            onSubmit(x.addCustomer);
          }
          props.form.setFieldsValue({
            name: "",
            mobileNo: "",
            email: "",
          });
        }
      }
    });
  };
  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "email",
        label: "Email ",
        span: "col-md-6",
        placeholder: "Enter Email",
        type: "email",
      },
      {
        key: "mobileNo",
        label: "Mobile No *",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "gstIn",
        label: "Gst No",
        span: "col-md-6",
        placeholder: "Gst",
      },
    ],
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="form-elements-wrapper">
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={` ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        <hr
          style={{
            marginBottom: "50px",
            marginTop: "60px",
            height: "2px",
            background: "grey",
          }}
        />
      </Form>
    </>
  );
};

export default Form.create()(AddCustomerComponent);
