import React, {useEffect, useRef, useState} from "react";
import {Card, Modal, Table, DefaultTablePagination, DatePicker, InputBox} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import moment from "moment";
import {fetchAllOrder} from "./actions";
import {getVegStatus, PaymentType} from "../../components/_utils/_utils";
import {getOldKotByOrderIdFxn, getPrintProductsFxn} from "../pos/actions";
import PrintDesign from "../print/printDesign";
import {useReactToPrint} from "react-to-print";
import {useDispatch} from "react-redux";
import {paymentGroupByPaymentMethodFxn} from "../dashboard/action";

function OrderList() {
    let dispatch = useDispatch();
    const componentRef = useRef();
    let tableRef = useRef();
    let [oldKots, setOldKots] = useState({
        total: 0,
        data: [],
    });
    let [isModalOpen, setIsModalOpen] = useState(false);
    let initPaymentMode = [
        {name: "Card", value: 0},
        {name: "Cash", value: 0},
        {name: "UPI", value: 0},
        {name: "Total", value: 0}
    ]
    const [paymentData, setPaymentData] = useState(initPaymentMode)
    console.log(paymentData, "payment data")

    let [data, setData] = useState({
        orderNo: "",
        totalAmount: "",
        cgstAmount: "",
        sgstAmount: "",
        serviceTaxAmount: "",
        netAmount: "",
        orderId: "",
    });
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });

    let [singleOrderData, setSingleOrderData] = useState({});
    let [choosePaymentType, setChoosePaymentType] = useState("");

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    let loadOrderData = async (orderId) => {
        let params = {orderId, count: 1000, results: 1000};
        let resp = await dispatch(getPrintProductsFxn(params));
        setSingleOrderData(resp);
    };

    useEffect(() => {
        if (singleOrderData && singleOrderData._id) {
            handlePrint();
        }
    }, [singleOrderData]);

    const loadPaymentData = async (params = {}) => {
        if (dates.fromDate && dates.toDate) {
            params.fromDate = dates.fromDate
            params.toDate = dates.toDate
        }
        if (choosePaymentType) {
            params.paymentType = choosePaymentType
        }
        let finalArr = []
        const {data} = await paymentGroupByPaymentMethodFxn({...params});
        let cloneDoc = _.cloneDeep(initPaymentMode);
        _.each(cloneDoc, (item) => {
            let findVal = _.find(data, (doc) => {
                return doc._id == item.name.toLowerCase()
            })

            if (findVal) {
                finalArr.push({
                    name: item.name,
                    value: findVal.totalAmount,
                    ...findVal
                })
                // item.value = findVal.totalAmount
            }
        })
        setPaymentData(finalArr)
    };

    useEffect(() => {
        loadPaymentData()
    }, [])

    const getOldKotData = (params = {}) => {
        return new Promise(async (resolve) => {
            try {
                const {data: kotData, total} = await getOldKotByOrderIdFxn({
                    ...params,
                    results: 1000,
                    count: 1000,
                    orderId: data.orderId,
                });
                if (kotData.total) {
                    setOldKots({
                        data: kotData.data,
                        total: kotData.total,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        getOldKotData();
    }, [data.orderId]);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (dates.fromDate && dates.toDate) {
                    params.date = {
                        $gte: moment(dates.fromDate).startOf('day')._d,
                        $lte: moment(dates.toDate).endOf('day')._d,
                    };
                }
                if (choosePaymentType) {
                    params.paymentType = choosePaymentType
                }
                params.status = {$in: ["Paid"]};
                // params.status = {$in: ["Done", "Paid"]};
                params.guest = false;
                // params.tableNo = {$not: {$regex: "g"}};
                const data = await fetchAllOrder({...params});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const refreshTable = () => {
        tableRef.current.reload();
        loadPaymentData()
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (item) => {
                return (
                    <>
                        {item == "Pending" ? (
                            <label className={"label label-warning label-style"}>
                                Pending
                            </label>
                        ) : (
                            ""
                        )}
                        {item == "Paid" ? (
                            <label className={"label label-success label-style"}>Paid</label>
                        ) : (
                            ""
                        )}
                        {item == "Done" ? (
                            <label className={"label label-primary label-style"}>Done</label>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
        {
            title: "Table No.",
            dataIndex: "tableNo",
            key: "tableNo",
        },
        {
            title: "Order No.",
            dataIndex: "orderNo",
            key: "orderNo",
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
        },
        {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
        },
        {
            title: "CGST Amount",
            dataIndex: "cgstAmount",
            key: "cgstAmount",
        },
        {
            title: "SGST Amount",
            dataIndex: "sgstAmount",
            key: "sgstAmount",
        },
        {
            title: "Service Tax Amount",
            dataIndex: "serviceTaxAmount",
            key: "serviceTaxAmount",
        },
        {
            title: "Net Amount",
            dataIndex: "netAmount",
            key: "netAmount",
        },
        {
            title: "Cash Discount",
            dataIndex: "cashDiscount",
            key: "cashDiscount",
        },
        {
            title: "Final Amount",
            dataIndex: "finalAmount",
            key: "finalAmount",
        },

        {
            title: "Setteled Amount",
            dataIndex: "setteledAmount",
            key: "setteledAmount",
        },
        {
            title: "customer Paid",
            dataIndex: "customerPaid",
            key: "customerPaid",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => (
                <span>{moment(record.date).format("DD/MM/YYYY")} </span>
            ),
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (text, record) => (
                <span>{moment(record.date).format("hh:mm A")} </span>
            ),
        },

        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, record) => {
                return (
                    <>
                        {record.status && record.status !== "Pending" ? (
                            <>
                                <a
                                    className="empty_btn"
                                    onClick={() => {
                                        setIsModalOpen(true);
                                        if (record) {
                                            setData({
                                                orderNo: record.orderNo,
                                                totalAmount: record.totalAmount,
                                                cgstAmount: record.cgstAmount,
                                                sgstAmount: record.sgstAmount,
                                                serviceTaxAmount: record.serviceTaxAmount,
                                                netAmount: record.netAmount,
                                                orderId: record._id,
                                            });
                                        }
                                    }}
                                >
                                    <i className={"fa fa-eye"}/>
                                </a>

                                <a
                                    className="empty_btn"
                                    onClick={() => {
                                        loadOrderData(record._id);
                                    }}
                                >
                                    <i className={"fa fa-print"}/>
                                </a>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
    ];
    const columns2 = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
        },
        {
            title: "CGST Amount",
            dataIndex: "cgstAmount",
            key: "cgstAmount",
        },
        {
            title: "SGST Amount",
            dataIndex: "sgstAmount",
            key: "sgstAmount",
        },
        {
            title: "Service Tax Amount",
            dataIndex: "serviceTaxAmount",
            key: "serviceTaxAmount",
        },
        {
            title: "Net Amount",
            dataIndex: "netAmount",
            key: "netAmount",
        },
    ];

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };

    const getColumns2 = () => {
        let columnArr = [];
        _.each(columns2, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };
    let orderN = (
        <div>
            Order Number : {data.orderNo} | <small style={{fontSize: 13}}> (No of Kots - {oldKots.total}) </small>
        </div>
    );

    let fixedAmount = (amount) => {
        return amount ? `Rs. ${amount.toFixed(2)}` : 0
    }

    return (
        <PageHeader title={"Order List"}>
            {/*{totalNetAmount}*/}

            <div className="row" key={paymentData ? JSON.stringify(paymentData) : "666"}>
                {paymentData && paymentData.length ? paymentData.map((item) => {
                    return (
                        <div className="col-lg-3 col-sm-6 col-12" key={item.name}>
                            <div className="dash-widget">
                                <div className="dash-widgetimg">
                                        <span>
                                          <img src="assets/img/icons/dash1.svg" alt="img"/>
                                        </span>
                                </div>
                                <div className="dash-widgetcontent" key={item.value}>
                                    <h5>
                                          <span className="counters" data-count={item.value}>
                                            Rs. {item.value ? item.value.toFixed(2) : 0}
                                          </span>
                                    </h5>

                                    <h5 className={'text-primary'}>{item.name}</h5>
                                    <div style={{fontSize: 12, marginTop: 10, color: "#555", fontWeight: "bold"}}>
                                        CGST : {fixedAmount(item.cgstAmount)}<br/>
                                        SGST : {fixedAmount(item.sgstAmount)}<br/>
                                        Service Tax : {fixedAmount(item.serviceTaxAmount)}<br/>
                                        Tip : {fixedAmount(item.tip)}<br/>
                                        Cash Dis. : {fixedAmount(item.cashDiscount)}<br/>
                                        {/*Settled : {fixedAmount(item.setteledAmount)}<br/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : null}
            </div>

            {/*<div className={"amountBlock item-flex"}>*/}
            {/*    <h4><b className={"fontBold"}>Amount :</b> Rs. {totalNetAmount}</h4>*/}
            {/*    <h4><b className={"fontBold"}>Cash :</b> Rs. {cash}</h4>*/}
            {/*    <h4><b className={"fontBold"}>Upi :</b> Rs. {upi}</h4>*/}
            {/*    <h4><b className={"fontBold"}>Card :</b> Rs. {card}</h4>*/}
            {/*</div>*/}
            <div className="row mb-3">

                <div className="col-md-2">
                    <InputBox title={'From Date'}>
                        <DatePicker
                            value={dates.fromDate}
                            showClear={true}
                            placeholder={'Choose from Date'}
                            onChange={(value) => {
                                setDates({...dates, fromDate: value})
                            }}
                        />
                    </InputBox>
                </div>
                <div className="col-md-2">
                    <InputBox title={'To Date'}>
                        <DatePicker
                            value={dates.toDate}
                            showClear={true}
                            placeholder={'Choose to Date'}
                            onChange={(value) => {
                                setDates({...dates, toDate: value})
                            }}
                        />
                    </InputBox>
                </div>
                <div className="col-md-3">
                    <InputBox title={'Payment Type'}>
                        <select className="form-control search-filters"
                                onChange={(e) => {
                                    setChoosePaymentType(e.target.value)
                                }}
                                value={choosePaymentType}
                        >
                            <option value={""}>Choose Payment Type</option>
                            {PaymentType.map((item) => {
                                return (
                                    <option value={item.key}>
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </InputBox>

                </div>
                <div className="col-md-1 mt-4">
                    <div className="search-wrap">
                        <button className="btn btn-info" onClick={refreshTable}>
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="card mb-0">
                <div className="table-responsive" style={{"overflow-y": 'hidden'}}>
                    <Table
                        apiRequest={apiRequest}
                        columns={getColumns()}
                        pagination={DefaultTablePagination()}
                        ref={tableRef}
                    />
                </div>
            </div>

            {isModalOpen && (
                <Modal
                    visible={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                    title={orderN}
                >
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Total Amount</td>
                            <td>Cgst Amount</td>
                            <td>Sgst Amount</td>
                            <td>Service Tax</td>
                            <td>Net Amount</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{data.totalAmount}</td>
                            <td>{data.cgstAmount}</td>
                            <td>{data.sgstAmount}</td>
                            <td>{data.serviceTaxAmount}</td>
                            <td>{data.netAmount}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div className={"mt-2"}>
                        {oldKots && oldKots && oldKots.data.length
                            ? oldKots.data.map((item, index) => {
                                return (
                                    <div key={item.kotNo}>
                                        <div className="dark-background d-flex justify-content-between">
                                            <h7>
                                                Kot - {item.kotNo} Time -{" "}
                                                {moment(item.time).format("hh:mm A")}
                                            </h7>
                                            <div>
                                                {/*<small className={'small'}>Sr No. ({index + 1})</small>*/}
                                            </div>
                                        </div>
                                        {item.products.map((product) => {
                                            return (
                                                <>
                                                    <ul
                                                        className="product-lists mb-0 p-1"
                                                        key={product._id}
                                                    >
                                                        <li className="w-50">
                                                            <div className="productimg">
                                                                <div className="productcontet">
                                                                    <h4 className={"mb-0"}>
                                                                        {product &&
                                                                        product.productId &&
                                                                        product.productId.vegNonVeg ? (
                                                                            <>
                                                                                {getVegStatus(
                                                                                    product.productId.vegNonVeg
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {product.name}{" "}
                                                                        {product.variant &&
                                                                        ` (${product.variant})`}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="increment-decrement">
                                                                <div className="input-groups">
                                                                    <h7>Qty:</h7>
                                                                    <input
                                                                        type="text"
                                                                        name="child"
                                                                        value={product.quantity}
                                                                        className="quantity-field"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div style={{fontSize: "80%"}}>
                                                                Rs. {product.price}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <strong>Rs. {product.totalAmount}</strong>
                                                        </li>
                                                    </ul>
                                                </>
                                            );
                                        })}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </Modal>
            )}

            <div style={{display: "none"}}>
                {singleOrderData && singleOrderData._id ? (
                    <PrintDesign ref={componentRef} singleOrderData={singleOrderData}/>
                ) : null}
            </div>
        </PageHeader>
    );
}

export default OrderList;
